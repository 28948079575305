import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HtmlContent, { htmlShape } from './HtmlContent';

import {
    componentMissmatchWarning,
    layoutClasses,
    layoutShape,
} from './helpers';

import {
    SECTION_OVERVIEW,
    COMPONENT_HTML,
} from './constants';

const { arrayOf, oneOf, shape, string } = PropTypes;

class OverviewSection extends React.Component {

    static propTypes = {
        className: string,
        layout: layoutShape,
        components: arrayOf(shape({
            type: oneOf([COMPONENT_HTML]),
            content: shape({ html: htmlShape }),
        })),
    };

    renderComponent(component, index) {
        const { type, content } = component;
        switch (type) {
        case COMPONENT_HTML:
            return (
                <HtmlContent
                    key={index}
                    className="overview__content mb-4"
                    html={content.html}
                />
            );
        default:
            return componentMissmatchWarning(type, SECTION_OVERVIEW);
        }
    }

    render() {
        const { className, components, layout } = this.props;
        const classes = classnames('section--overview', className, layoutClasses(layout));

        // Fail silently if no components
        if (!components) return null;

        return (
            <div className={classes}>
                {components.map(this.renderComponent)}
            </div>
        );
    }
}

export default OverviewSection;
