import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M10.1,10.2 L7.6,10.2 L7.6,12.4 L10.1,12.4 L10.1,19 L13,19 L13,12.4 L16,12.4 L16,10.2 L13.1,10.2 L13.1,9.3 C13.1,8.6 13.8,7.2 14.8,7.2 L16,7.2 L16,5 L13.4,5 C9.8,5.5 10.1,10.2 10.1,10.2 Z" fill="currentColor" fillRule="nonzero" stroke="none" strokeWidth="1"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <path d="M20.1,20.4 L15.1,20.4 L15.1,24.8 L20.1,24.8 L20.1,38 L26,38 L26,24.8 L31.9,24.8 L31.9,20.4 L26,20.4 L26,18.6 C26,17.3 27.4,14.5 29.4,14.5 L31.8,14.5 L31.8,10.1 L26.7,10.1 C19.5,11.2 20.1,20.4 20.1,20.4 Z" fill="currentColor" fillRule="nonzero" stroke="none" strokeWidth="1"/>
    </svg>
  );
};
