import React from 'react';
import VideoJS from './VideoJS';

// https://videojs.com/guides/react/

const HeroVideo = ({ video, poster }) => {

  let videoJsOptions = {
    autoplay: false,
    muted: true,
    loop: true,
    controls: false,
    fluid: true,
    // sources: [{
    //   src: `${process.env.RAZZLE_CDN}/video/audi-e-tron-trimmed.mp4`,
    //   type: 'video/mp4'
    // }],
    sources: [{
      src: video,
      type: 'video/mp4'
    }],
    poster: poster
  };
  if (typeof window !== "undefined") {
    if (window.navigator && /Android|iPhone/i.test(window.navigator.userAgent)) {
      videoJsOptions.autoplay = false;
    } else {
      videoJsOptions.autoplay = true;
    }
  }

  return (
    <VideoJS options={videoJsOptions} onReady={(player) => {
      let p = player.play();
      // console.log("p", p);
      if (p !== undefined) {
        p.then(()=>{
          // console.log("playing");
        }).catch(()=>{
          // console.log("not playing");
          player.controls(true);
        });
      }
    }} />
  );
}

export default HeroVideo;
