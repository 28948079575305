import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

class Button extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showResponse: false,
      responseLeft: '50%',
      responseTop: '50%'
    };
    let variant = props.variant || props.buttonType;
    this._boxedButton = variant === 'primary' || variant === 'secondary' || variant === 'floating';
    this._useTriggerX = this._boxedButton || (!variant === 'icon' && !variant === 'icon-large');
    this._useTriggerY = this._boxedButton;
    this._responseTimeout = null;
  }

  componentWillUnmount() {
    if (this._responseTimeout !== null) {
      clearTimeout(this._responseTimeout);
    }
  }

  getButtonClass = () => {
    const { isPadded, isBold, isStretched, isSticky, disabled, className, theme } = this.props;
    let variant = this.props.variant || this.props.buttonType;
    let c = "aui-button aui-js-response " + className;
    if (variant) {
      c += ` aui-button--${variant}`;
      if (variant === 'icon') {
        c += ' aui-button--round';
        if (this.props.iconSize === 'large' || this.props.icon.props.size === 'large' || this.props.icon.props.large) {
          c += ' aui-button--icon--large';
        }
      }
    }
    if (disabled) { c += " is-disabled"; }
    if (isPadded) { c += " aui-button--padded"; }
    if (isBold) { c += " aui-button--bold"; }
    if (isStretched) { c += " aui-button--stretched"; }
    if (isSticky) { c += " aui-button--sticky"; }
    if (theme) { c += ` aui-theme-${theme}`; }
    return c;
  }

  handleMouseDown = (event) => {
    if (!this.props.disabled) {
      // Get cross-browser offsetX, offsetY
      let target = event.currentTarget || event.srcElement,
        rect = target.getBoundingClientRect(),
        offsetX = event.clientX - rect.left,
        offsetY = event.clientY - rect.top;
      let left = this._useTriggerX ? `${offsetX}px` : '50%';
      let top = this._useTriggerY ? `${offsetY}px` : '50%';
      this.setState({showResponse: true, responseLeft: left, responseTop: top});
      this._responseTimeout = setTimeout(() => { this.setState({showResponse: false}); this._responseTimeout = null; }, 1000);
      // call click function
      if (typeof this.props.onClick === 'function') {
        this.props.onClick(event);
      }
    }
  }
  
  renderInner = () => {
    const {children, icon, iconPosition} = this.props;
    let variant = this.props.variant || this.props.buttonType;
    let label = children || this.props.label;
    const hasLabel = label && variant !== 'floating' && variant !== 'icon' && variant !== 'icon-large';
    if (!hasLabel && icon) {
      return (
        <>
          <span className="aui-sr-only">{label}</span>
          {icon}
        </>
      );
    }
    if (hasLabel && !icon) {
      return (
        <span className="aui-button__text">{label}</span>
      );
    }
    return (
      <span className="aui-button__text-icon">
        {iconPosition === 'left' && icon}
        <span className="aui-button__text">{label}</span>
        {iconPosition === 'right' && icon}
      </span>
    );
  }
  
  renderResponse = () => {
    const {showResponse, responseLeft, responseTop} = this.state;
    if (showResponse) {
      return (
        <span className={classnames('aui-response', 'is-animating', {'aui-response--masked': this._boxedButton})}>
          <span className="aui-response__effect" style={{left: responseLeft, top: responseTop}}></span>
        </span>
      );
    }
    return null;
  }

  render() {
    if (this.props.href) {
      return (
        <a href={this.props.href} target={this.props.target} rel={this.props.rel} className={this.getButtonClass()} onMouseDown={this.handleMouseDown} style={this.props.style}>
          {this.renderInner()}
          {this.renderResponse()}
        </a>
      );
    } else if (this.props.to) {
      return (
        <Link to={this.props.to} className={this.getButtonClass()} onMouseDown={this.handleMouseDown} style={this.props.style}>
          {this.renderInner()}
          {this.renderResponse()}
        </Link>
      );
    } else if (typeof this.props.onClick === "function" || this.props.type === "submit") {
      return (
        <button className={this.getButtonClass()} type={this.props.type} disabled={this.props.disabled} onMouseDown={this.handleMouseDown} style={this.props.style}>
          {this.renderInner()}
          {this.renderResponse()}
        </button>
      );
    }
    return (
      <span className={this.getButtonClass()} style={this.props.style}>
        {this.renderInner()}
        {this.renderResponse()}
      </span>
    );
  }

}

Button.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  iconSize: PropTypes.oneOf(['small', 'large']), // use this on icon buttons if the icon size can't be auto-detected
  variant: PropTypes.oneOf(['primary', 'secondary', 'text', 'floating', 'icon']),
  type: PropTypes.string,
  isPadded: PropTypes.bool,
  isBold: PropTypes.bool,
  isStretched: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClick: PropTypes.func,
  href: PropTypes.string, // will cause this component to output an <a> tag instead of <button>
  target: PropTypes.string, // only used with href
  rel: PropTypes.string, // only used with href
  disabled: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_button-themes.scss
}

Button.defaultProps = {
  type: 'button',
  iconPosition: 'left',
  style: {},
  className: "",
  target: "_self",
  rel: ""
}

export default Button;

export const PrimaryButton = (props) => {
  let {children, ...rest} = props;
  return (<Button {...rest} variant="primary">{children}</Button>);
}

export const SecondaryButton = (props) => {
  let {children, ...rest} = props;
  return (<Button {...rest} variant="secondary">{children}</Button>);
}

export const TextButton = (props) => {
  let {children, ...rest} = props;
  return (<Button {...rest} variant="text">{children}</Button>);
}

export const FloatingButton = (props) => {
  let {children, ...rest} = props;
  return (<Button {...rest} variant="floating">{children}</Button>);
}

export const IconButton = (props) => {
  let {children, ...rest} = props;
  return (<Button {...rest} variant="icon">{children}</Button>);
}
