import API from '../../functions/api';

const ARTICLES_CONTENT_LOADED = 'articles/content/loaded';
const ARTICLES_CONTENT_REQUESTED = 'articles/content/requested';

export function requestArticleData(slug, cb) {
    return (dispatch) => {
        if (!slug || slug === undefined) {
            return null;
        }

        dispatch({
            type: ARTICLES_CONTENT_REQUESTED,
            slug,
        });

        return API.get(`/articles/${slug}`)
        .then((res) => {
            dispatch({
                type: ARTICLES_CONTENT_LOADED,
                data: res.data,
                slug: slug,
                status: 200
            });
            if (typeof cb === "function") { cb(); }
            return Promise.resolve();
        })
        .catch((error) => {
            // console.log(`Couldn't get article:`, error);
            dispatch({
                type: ARTICLES_CONTENT_LOADED,
                data: {},
                slug: slug,
                status: error.response.status
            });
            return Promise.resolve({status: error.response.status});
        });
    };
}

const defaultContentState = {};

export default function articles(state = defaultContentState, action) {
    switch (action.type) {
    case ARTICLES_CONTENT_REQUESTED:
        return Object.assign({}, state, {
            [action.slug]: {
                isFetching: true,
            },
        });
    case ARTICLES_CONTENT_LOADED:
        return Object.assign({}, state, {
            [action.slug]: {
                isFetching: false,
                hasLoaded: true,
                status: action.status,
                ...action.data,
            },
        });
    default:
        return state;
    }
}
