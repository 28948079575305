import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import Loading from '../components/Loading';
import Hero from '../components/Hero';
import Button from '../audi-ui-components/Button';
import NavBar from '../audi-ui-components/NavBar';
import { CMSSectionList } from '../components/cms';
import Social from '../components/Social';
import _findIndex from 'lodash/findIndex';
import { gtmPageview, gtmPush } from '../functions/gtm';
import NotFound from './NotFound';

import {
  PATH_PARTNERS
} from '../constants';

import { connect } from 'react-redux';
import { requestPartnerData } from '../redux/content/partners';
import { requestPartners } from '../redux/content/partnersList';
const mapStateToProps = state => {
  return {
    partners: state.partners,
    partnersList: state.partnersList.data
  };
};
const mapDispatchToProps = dispatch => {
  return {
    requestPartnerData: (slug) => { dispatch(requestPartnerData(slug)); },
    requestPartners: () => { dispatch(requestPartners()); }
  }
};

const PartnerSingle = ({ match, location, partners, partnersList, requestPartnerData, requestPartners }) => {
  
  const [activeNav, setActiveNav] = useState(null);
  
  useEffect(() => {
    gtmPageview(location.pathname);
    if (match && match.params) {
      requestPartnerData(match.params.slug);
    }
    if (!partnersList || partnersList.length === 0) {
      requestPartners();
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [match]);
  
  useEffect(() => {
    if (partnersList && partnersList.length > 0) {
      let i = _findIndex(partnersList, {slug: match.params.slug});
      if (i === -1) {
        setActiveNav(null);
      } else {
        setActiveNav(i)
      }
    }
  }, [match, partnersList]);

  const partnerData = (partners && partners[match.params.slug]) ? partners[match.params.slug] : {};
  return (
    <div className="page partner-single">
      {partnerData?.status === 200 && <Helmet>
        <title>{`${partnerData.title} | Audi Foundation`}</title>
        <meta name="description" content={partnerData.description} />
        <meta property="og:title" content={partnerData.title} />
        <meta property="og:type" content="article" />
        <meta property="og:image" content={partnerData.heroImage} />
        <meta property="og:description" content={partnerData.description} />
      </Helmet>}
      
      {partnersList && <div className="aui-color-gray10 content-wrapper subnav">
        <NavBar actions={partnersList.map(p => { return {to: p.slug, text: p.title}; })} activeIndex={activeNav} theme="black" />
      </div>}
      
      {partnerData && partnerData.heroImage && <Hero heroImage={partnerData.heroImage} />}
      
      <div className="page-inner">
        <div className="content-wrapper">
          {(!partnerData.hasLoaded) && <Loading />}
          
          {partnerData?.status > 200 && <NotFound />}
          
          {partnerData && partnerData.status === 200 && <div className="page-intro">
            <h1 className="aui-headline-2 mb-3"><b>{partnerData.title}</b></h1>
              <p className="intro__text">
                  {partnerData.description}
              </p>
              {partnerData.logo &&
                <p><img src={partnerData.logo} alt={partnerData.title} className="partner__logo"/></p>
              }
          </div>}
          
          {partnerData?.sections && <CMSSectionList sections={partnerData.sections} />}
          
          {partnerData?.partnerUrl &&
            <div className="text-center mt-5 mb-3">
              <Button
                variant="primary"
                href={partnerData.partnerUrl}
                target="_blank"
                rel="nofollow"
                onClick={() => { gtmPush("audiFoundation", "partnerWebsiteClick", `partner_name = ${partnerData.title}`, {title: match.params.slug}); }}
              >
                Visit website
              </Button>
            </div>
          }
          
          {partnerData?.hasLoaded && partnerData.status === 200 && <Social share />}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerSingle);
