import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HtmlContent, { htmlShape } from './HtmlContent';
import {unOrphan} from './helpers'
import {
    componentMissmatchWarning,
    layoutClasses,
    layoutShape,
} from './helpers';

import {
    SECTION_OVERVIEW,
    COMPONENT_HTML,
} from './constants';

const { arrayOf, oneOf, shape, string } = PropTypes;

class HtmlSection extends React.Component {

    renderComponent(component, index) {
        const { type, content } = component;
        switch (type) {
        case COMPONENT_HTML:
            return (
                <HtmlContent
                    key={index}
                    className="html__content"
                    html={unOrphan(content.html)}
                />
            );
        default:
            return componentMissmatchWarning(type, SECTION_OVERVIEW);
        }
    }

    render() {
        const { className, components, layout } = this.props;
        const classes = classnames('section--html', className, layoutClasses(layout));

        if (!components) return null;

        return (
            <div className={classes}>
                {components.map(this.renderComponent)}
            </div>
        );
    }
}

export default HtmlSection;
