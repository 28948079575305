export function truncateString(str, noSentences = 2) {
    if (!str) return null;
    let sentences = str.match(/[^\.!\?]+[\.!\?]+/g);
    if (!Array.isArray(sentences)) {
        return str;
    }
    sentences = sentences
    .filter(sentence => sentence !== '')
    .slice(0, noSentences)
    .join('');

    return sentences;
}

export function joinTagsObject(tags) {
    if (!tags) {return "";}
    return Object.keys(tags).reduce((res, key) => `${res} ${tags[key].name}`, '').trim();
}

export function formatMobile(value) {
  let _v = value.replace(/\D/g,'').substring(0,10); // First ten digits of input only
  let zip = _v.substring(0,4);
  let middle = _v.substring(4,7);
  let last = _v.substring(7,10);
  if(_v.length > 7) {
    return `${zip} ${middle} ${last}`;
  }
  else if(_v.length > 4) {
    return `${zip} ${middle}`;
  }
  else if(_v.length > 0) {
    return `${zip}`;
  }
  else {
    return '';
  }
}

export function formatDate(value) {
  // DD/MM/YYYY
  if (!value) { return ""; }
  let n = value.replace(/\D/g,'').substring(0,8);
  if (n.length > 2) {
    if (n.length < 5) {
      n = n.replace(/^([\d]{2})([\d]{1,2})$/,"$1/$2")
    } else {
      n = n.replace(/^([\d]{2})([\d]{2})([\d]{1,4})$/,"$1/$2/$3")
    }
  }
  return n;
}
