import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import TextField from '../audi-ui-components/TextField';
import Select from '../audi-ui-components/Select';
import Button from '../audi-ui-components/Button';
import IconEdit from '../audi-ui-components/icons/Edit';
import IconCancel from '../audi-ui-components/icons/Cancel';
import { Wrapper, Status } from '@googlemaps/react-wrapper';

const allAddressFields = {addressLine1: true, addressLine2: true, addressCity: true, addressPostcode: true, addressState: true, addressFull: true};

// https://developers.google.com/maps/documentation/javascript/examples/places-autocomplete-addressform

const AddressFields = ({ values, errors, touched, setTouched, submitCount, setValues, stateOpts }) => {
  
  const handleChange = (fieldName, v) => {
    // console.log("AddressFields handleChange", fieldName, v);
    // setFieldValue(fieldName, v);
    let addressLine1 = fieldName === "addressLine1" ? v : values.addressLine1;
    let addressLine2 = fieldName === "addressLine2" ? v : values.addressLine2;
    let addressCity = fieldName === "addressCity" ? v : values.addressCity;
    let addressPostcode = fieldName === "addressPostcode" ? v : values.addressPostcode;
    let addressState = fieldName === "addressState" ? v : values.addressState;
    let address = [
        addressLine1, addressLine2, `${addressCity} ${addressState} ${addressPostcode}`, "Australia"
    ].filter(a => a).join(', ');
    let newValues = Object.assign({}, values, {addressFull: address, [fieldName]: v});
    setValues(newValues);
    setTouched(Object.assign({}, touched, allAddressFields), false);
  }
  
  const getErrMsg = (err) => {
    if (typeof err === "string") {
      return err;
    }
    if (Array.isArray(err)) {
      return err[0];
    }
    return "";
  }
  
  return (
    <div className="pb-1">
      <TextField name="addressLine1"
        label="Address line 1"
        value={values.addressLine1 || ""}
        onChange={(v) => { handleChange("addressLine1", v); }}
        isInvalid={Boolean(errors.addressLine1 && ((touched && touched.addressLine1) || submitCount > 0))}
        invalidMessage={getErrMsg(errors.addressLine1)}
      />
      <div className="row">
        <div className="col-12 col-small-6">
          <TextField name="addressLine2"
            label="Address line 2 (optional)"
            value={values.addressLine2 || ""}
            onChange={(v) => { handleChange("addressLine2", v); }}
          />
        </div>
        <div className="col-12 col-small-6">
          <TextField name="addressCity"
            label="City"
            value={values.addressCity || ""}
            onChange={(v) => { handleChange("addressCity", v); }}
            isInvalid={Boolean(errors.addressCity && ((touched && touched.addressCity) || submitCount > 0))}
            invalidMessage={getErrMsg(errors.addressCity)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-small-6">
          <TextField name="addressPostcode"
            label="Postcode"
            value={values.addressPostcode || ""}
            onChange={(v) => { handleChange("addressPostcode", v.trim()); }}
            isInvalid={Boolean(errors.addressPostcode && ((touched && touched.addressPostcode) || submitCount > 0))}
            invalidMessage={getErrMsg(errors.addressPostcode)}
          />
        </div>
        <div className="col-12 col-small-6">
          <Select name="addressState"
            label="State"
            value={values.addressState || ""}
            onChange={(v) => { handleChange("addressState", v); }}
            options={stateOpts}
            isInvalid={Boolean(errors.addressState && ((touched && touched.addressState) || submitCount > 0))}
            invalidMessage={getErrMsg(errors.addressState)}
          />
        </div>
      </div>
    </div>
  );
};
AddressFields.defaultProps = {
  stateOpts: ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA']
};

class WrappedAddressField extends React.Component {
  
  // the google listener is too fiddly on a function component - just leave it.
  
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
    this.state = {
      showFields: false,
    };
  }
  
  componentDidMount() {
    this.autoComplete = new google.maps.places.Autocomplete(this.inputRef.current, {
      componentRestrictions: { country: ["AU"] },
      fields: ["address_components", "formatted_address"],
      types: ["address"],
      placeholder: "foobar"
    });
    this.autoComplete.addListener('place_changed', this.handlePlaceChanged);
  }
  
  handlePlaceChanged = () => {
    // console.log("WrappedAddressField handlePlaceChanged");
    let place = this.autoComplete.getPlace();
    let addressComponents = {
      addressFull: "",
      addressLine1: "",
      addressLine2: "",
      addressPostcode: "",
      addressCity: "",
      addressState: ""
    };
    // console.log("handlePlaceChanged", place);
    if (place && place.formatted_address) {
      addressComponents.addressFull = place.formatted_address;
    }
    if (place && place.address_components) {
      for (let component of place.address_components as google.maps.GeocoderAddressComponent[]) {
        let componentType = component.types[0];
        switch (componentType) {
          case "street_number": {
            addressComponents.addressLine1 = `${component.long_name} ${addressComponents.addressLine1}`;
            break;
          }
          case "route": {
            addressComponents.addressLine1 += component.short_name;
            break;
          }
          case "postal_code": {
            addressComponents.addressPostcode = component.long_name;
            break;
          }
          case "locality": {
            addressComponents.addressCity = component.long_name;
            break;
          }
          case "administrative_area_level_1": {
            addressComponents.addressState = component.short_name;
            break;
          }
        }
      }
    }
    let newValues = Object.assign({}, this.props.values, addressComponents);
    this.props.setValues(newValues);
    this.props.setTouched(Object.assign({}, this.props.touched, allAddressFields));
  }
  
  render() {
    const { values, errors, touched, submitCount, expandOnError } = this.props;
    const { showFields } = this.state;
    const hasErrors = errors && (errors.addressFull || errors.addressLine1 || errors.addressCity || errors.addressPostcode || errors.addressState);
    const isInvalid = hasErrors && ((touched && touched.addressFull) || submitCount > 0);
    let errMsg = "";
    if (errors.addressFull) {
      if (typeof errors.addressFull === 'string') {
        errMsg = errors.addressFull;
      } else if (Array.isArray(errors.addressFull)) {
        errMsg = errors.addressFull[0];
      }
    } else if (hasErrors) {
      errMsg = errors.addressLine1 || errors.addressCity || errors.addressPostcode || errors.addressState;
      if (Array.isArray(errMsg)) {
        errMsg = errMsg[0];
      }
    }
    return (
      <div className={classnames("address-field mb-3", {"open": showFields || (isInvalid && expandOnError)})}>
          
        <div className="row align-items-start">
          <div className="col">
            <TextField name="addressFull"
              ref={this.inputRef}
              value={values.addressFull || ""}
              label="Enter your address"
              placeholder=""
              onChange={(v) => { this.props.setFieldValue("addressFull", v); }}
              isInvalid={isInvalid}
              invalidMessage={errMsg}
            />
          </div>
          <div className="col-auto">
            <Button
              icon={showFields ? <IconCancel small /> : <IconEdit small />}
              variant="icon"
              className="mt-3"
              onClick={() => { this.setState({showFields: !showFields}); }}>edit</Button>
          </div>
        </div>
        
        <div className="address-fields-wrapper">
          <AddressFields {...this.props} />
        </div>
        
      </div>
    );
  }
}

const AddressField = (props) => {
  return (
    <Wrapper apiKey={process.env.RAZZLE_GOOGLE_MAPS_API_KEY} libraries={["places"]} render={(status) => {
      if (status === Status.FAILURE) { return (<AddressFields {...props} />); }
      return null;
    }}>
      <WrappedAddressField {...props} />
    </Wrapper>
  )
}

export default AddressField;
