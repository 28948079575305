import API from '../../functions/api';

const TAGS_LOADED = 'tags/loaded';
const TAGS_REQUESTED = 'tags/requested';

export function requestArticleTags() {
    return (dispatch) => {
        dispatch({
            type: TAGS_REQUESTED,
        });
        const path = API_ARTICLE_TAGS;
        return API.get('/tags')
        .then(res => res.json())
        .then((data) => {
            dispatch({
                type: TAGS_LOADED,
                data,
            });
        })
        .catch((error) => {
            console.log(`Couldn't get tags:`, error);
        });
    };
}

const defaultContentState = {
    hasLoaded: false,
    isFetching: false,
    data: null,
};

export default function articleTags(state = defaultContentState, action) {
    switch (action.type) {
    case TAGS_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case TAGS_LOADED:
        return Object.assign({}, state, {
            hasLoaded: true,
            isFetching: false,
            data: action.data,
        });
    default:
        return state;
    }
}
