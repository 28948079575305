import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';

import './styles/main.scss';

import Home from './pages/Home';
import Partners from './pages/Partners';
import PartnerSingle from './pages/PartnerSingle';
import Stories from './pages/Stories';
import StorySingle from './pages/StorySingle';
import Supporters from './pages/Supporters';
import About from './pages/About';
import Subscribe from './pages/Subscribe';
import Donate from './pages/Donate';
import PrizeDraw from './pages/PrizeDraw';
import Faq from './pages/Faq';
import NotFound from './pages/NotFound';

import { requestPageData } from './redux/content/pages';
import { requestPartnerCategories } from './redux/content/partnerCategoriesList';
import { requestPartners } from './redux/content/partnersList';
import { requestPartnerData } from './redux/content/partners';
import { requestArticles } from './redux/content/articlesList';
import { requestArticleData } from './redux/content/articles';
import { requestDrawData } from './redux/content/prizedraw';

import {
  PATH_ABOUT,
  PATH_PARTNERS,
  PATH_STORIES,
  PATH_STORIES_SINGLE,
  PATH_STORIES_TAG,
  PATH_SUPPORTERS,
  PATH_SUBSCRIBE,
  PATH_DONATE,
  PATH_PRIZE_DRAWS,
  PATH_FAQ
} from './constants';

// server-side data fetching
export const ssrRoutes = [
  {
    path: "/",
    exact: true,
    loadData: (dispatch, matchParams, query) => {
      return Promise.all([
        dispatch(requestPageData('home')),
        dispatch(requestPartnerCategories())
      ]);
    }
  },
  {
    path: `${PATH_PARTNERS}/:slug`,
    loadData: (dispatch, matchParams, query) => {
      return dispatch(requestPartnerData(matchParams.slug));
    }
  },
  {
    path: PATH_PARTNERS,
    loadData: (dispatch, matchParams, query) => {
      return Promise.all([
        dispatch(requestPageData('partners')),
        dispatch(requestPartners())
      ]);
    }
  },
  {
    path: `${PATH_STORIES_SINGLE}/:slug`,
    loadData: (dispatch, matchParams, query) => {
      return dispatch(requestArticleData(matchParams.slug));
    }
  },
  {
    path: PATH_STORIES,
    loadData: (dispatch, matchParams, query) => {
      return dispatch(requestArticles({pageIndex: 0, tag: "all"}));
    }
  },
  {
    path: `${PATH_SUPPORTERS}/:state?`,
    loadData: (dispatch, matchParams, query) => {
      return dispatch(requestPageData('supporters'));
    }
  },
  {
    path: PATH_ABOUT,
    loadData: (dispatch, matchParams, query) => {
      return dispatch(requestPageData('about'));
    }
  },
  {
    path: PATH_PRIZE_DRAWS,
    loadData: (dispatch, matchParams, query) => {
      return dispatch(requestDrawData());
    }
  }
];

const App = (props) => (
  <div className="page-wrapper">
    <Header match={props.match} location={props.location} />
    <main className="main">
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path={`${PATH_PARTNERS}/:slug`} exact render={props => <PartnerSingle key={props.match.params.slug} {...props} />} />
        <Route path={PATH_PARTNERS} exact component={Partners} />
        <Route path={`${PATH_STORIES_SINGLE}/:slug`} exact render={props => <StorySingle key={props.match.params.slug} {...props} />} />
        <Route path={`${PATH_STORIES_TAG}/:tag`} exact component={Stories} />
        <Route path={PATH_STORIES} exact component={Stories} />
        <Route path={`${PATH_SUPPORTERS}/:state?`} exact render={props => <Supporters key={props.match.params.state || 'supporters'} {...props} />} />
        <Route path={PATH_ABOUT} exact component={About} />
        <Route path={PATH_SUBSCRIBE} exact component={Subscribe} />
        <Route path={PATH_DONATE} exact component={Donate} />
        <Route path={PATH_PRIZE_DRAWS} exact component={PrizeDraw} />
        <Route path={`${PATH_FAQ}/:slug?`} exact render={props => <Faq key={props.match.params.slug || 'faq'} {...props} />} />
        <Route path="*" render={({ staticContext }) => {
          if (staticContext) { staticContext.status = 404; }
          return <NotFound isPage />
        }} />
      </Switch>
    </main>
    <Footer />
  </div>
);

export default withRouter(App);
