import React from 'react';
import Helmet from 'react-helmet';
import { CMSSectionList } from '../components/cms';
import Hero from '../components/Hero';
import Loading from '../components/Loading';
import Accordion from 'react-bootstrap/Accordion';
import { gtmPageview } from '../functions/gtm';

import {
  PATH_FAQ,
} from '../constants';

import { connect } from 'react-redux';
import { requestPageData } from '../redux/content/pages';
const mapStateToProps = state => {
  return {
    hasLoaded: state.pages.faq ? state.pages.faq.hasLoaded : false,
    pageData: state.pages.faq
  }
}
const mapDispatchToProps = dispatch => {
  return {
    requestPageData: (slug) => { dispatch(requestPageData(slug)); }
  }
}

class Faq extends React.Component {
  
  componentDidMount() {
    gtmPageview(this.props.location.pathname);
    if (!this.props.hasLoaded) {
      this.props.requestPageData('faq');
    } else {
      if (this.props.match.params.slug) {
        this.scrollToQ(this.props.match.params.slug);
      }
    }
    if (!this.props.match.params.slug) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.hasLoaded && !prevProps.hasLoaded) {
      if (this.props.match.params.slug) {
        this.scrollToQ(this.props.match.params.slug);
      }
    }
    if (this.props.match.params.slug !== prevProps.match.params.slug) {
      gtmPageview(`${PATH_FAQ}/${this.props.match.params.slug}`);
    }
  }
  
  scrollToQ = (slug) => {
    setTimeout(() => {
      let el = document.getElementById(slug);
      if (el) {
        let ww = window.innerWidth;
        let header = 119;
        if (ww < 768) { header = 45; }
        let rect = el.getBoundingClientRect();
        let top = rect.top + window.pageYOffset;
        window.scrollTo({top: top - header, behaviour: 'smooth'});
      }
    }, 500);
  }
  
  render() {
    const { pageData, hasLoaded, match } = this.props;
    return (
      <div className="page faq">
        <Helmet>
          <title>FAQs | Audi Foundation</title>
          <meta name="description" content="Audi Foundation FAQs" />
          <meta property="og:site_name" content="Audi Foundation FAQs" />
          <link rel="canonical" href="https://audifoundation.com.au/faq/" />
        </Helmet>

        {pageData && pageData.heroImage && <Hero heroImage={pageData.heroImage} />}
        
        <div className="page-inner">
          
          <div className="content-wrapper">
            {(!pageData || !hasLoaded) && <Loading />}
            {pageData && pageData.sections && <CMSSectionList sections={pageData.sections} />}
          </div>
          
          {pageData && pageData.faqs && <div className="faq-list">
            {pageData.faqs.map((faq,i) => {
              return (
                <div className="faq-section" key={`faqs${i}`}>
                  {faq.header && <p className="aui-headline-4 text-center mb-2">{faq.header}</p>}
                  {faq.rows && <Accordion alwaysOpen defaultActiveKey={[match.params.slug]}>
                    {faq.rows.map(row => (
                      <Accordion.Item eventKey={row.slug} key={row.slug} id={row.slug}>
                        <Accordion.Header as="div" className="aui-headline-5 content-wrapper">
                          <span dangerouslySetInnerHTML={{__html: row.question}} />
                        </Accordion.Header>
                        <Accordion.Body className="px-0 py-4">
                          <div className="content-wrapper" dangerouslySetInnerHTML={{__html: row.answer}} />
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>}
                </div>
              );
            })}
          </div>}
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
