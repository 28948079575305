import React, { useState, useEffect } from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import Hero from '../components/Hero';
import Button from '../audi-ui-components/Button';
import { CMSSectionList } from '../components/cms';
import Social from '../components/Social';
import NavBar from '../audi-ui-components/NavBar';
import Thumbnail from '../components/Thumbnail';
import AudiRingsLogo from '../components/AudiRingsLogo';
import Map from '../components/Map';
import _findIndex from 'lodash/findIndex';
import _filter from 'lodash/filter';
import { gtmPageview, gtmPush } from '../functions/gtm';

import {
  PATH_SUPPORTERS,
} from '../constants';

import { connect } from 'react-redux';
import { requestDealers } from '../redux/content/dealers';
import { requestPageData } from '../redux/content/pages';
const mapStateToProps = state => {
  return {
    dealers: state.dealers,
    pageData: state.pages.supporters,
    hasLoaded: state.pages.supporters ? state.pages.supporters.hasLoaded : false,
  };
}
const mapDispatchToProps = dispatch => {
  return {
    requestPageData: (slug) => { dispatch(requestPageData(slug)); },
    requestDealers: (cb) => { dispatch(requestDealers(cb)); }
  }
}

const statesNav = [
  {to: `${PATH_SUPPORTERS}/nsw`, slug: "nsw", text: "NSW", name: "New South Wales"},
  {to: `${PATH_SUPPORTERS}/act`, slug: "act", text: "ACT", name: "Australian Capital Territory"},
  {to: `${PATH_SUPPORTERS}/vic`, slug: "vic", text: "VIC", name: "Victoria"},
  {to: `${PATH_SUPPORTERS}/qld`, slug: "qld", text: "QLD", name: "Queensland"},
  {to: `${PATH_SUPPORTERS}/sa`, slug: "sa", text: "SA", name: "South Australia"},
  {to: `${PATH_SUPPORTERS}/wa`, slug: "wa", text: "WA", name: "Western Australia"},
  {to: `${PATH_SUPPORTERS}/tas`, slug: "tas", text: "TAS", name: "Tasmania"},
  {to: `${PATH_SUPPORTERS}/nt`, slug: "nt", text: "NT", name: "Northern Territory"}
];

const Supporters = ({ match, location, history, pageData, hasLoaded, dealers, requestPageData, requestDealers }) => {
  
  const [activeNav, setActiveNav] = useState(null);
  const [filteredDealers, setFilteredDealers] = useState(null);
  
  const getFilteredDealers = (data) => {
    if (match.params && match.params.state && (dealers.hasLoaded || data)) {
      let list = data || dealers.data;
      let f = _filter(list, function(o) {
        return o.state.toLowerCase() === match.params.state.toLowerCase();
      });
      setFilteredDealers(f);
    }
  }
  
  useEffect(() => {
    if (!hasLoaded) {
      requestPageData('supporters');
    }
    if (!dealers || !dealers.hasLoaded) {
      requestDealers(getFilteredDealers);
    }
    getFilteredDealers();
  }, []);
  
  useEffect(() => {
    if (match.params && match.params.state) {
      let i = _findIndex(statesNav, function(o) { return o.slug === match.params.state.toLowerCase(); });
      if (i === -1) {
        setActiveNav(null);
      } else {
        setActiveNav(i);
      }
    } else {
      setActiveNav(null);
    }
  }, [match, statesNav]);
  
  useEffect(() => {
    gtmPageview(location.pathname);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [location]);

  return (
    <div className="page supporters">
      <Helmet>
        <title>Supporters | Audi Foundation</title>
        <meta name="description" content="Audi Foundation Supporters" />
        <meta property="og:site_name" content="Audi Foundation Supporters" />
      </Helmet>
      
      <div className="aui-color-gray10 content-wrapper subnav">
        <NavBar actions={statesNav} activeIndex={activeNav} theme="black" />
      </div>
      
      {pageData && pageData.heroImage && <Hero heroImage={pageData.heroImage} className="aui-color-text-light"><AudiRingsLogo /></Hero>}
      
      <div className="page-inner">
        <div className="content-wrapper">
          
          {pageData && <div className="page-intro">
            <h1 className="aui-headline-2 mb-3"><b>{pageData.title}</b></h1>
              <p className="intro__text">
                  {pageData.shortDescription}
              </p>
          </div>}
          
          {pageData && pageData.sections && <CMSSectionList sections={pageData.sections} />}
          
          {match.params.state && <div>
            {(activeNav !== null) && <h4 className="aui-headline-4 mb-3 text-center">{statesNav[activeNav].name}</h4>}
            <div className="row">
              {filteredDealers && filteredDealers.map((dealer, i) => {
                return (
                  <div key={`dealer${i}`} className="col-12 col-small-6 col-medium-4">
                    <Thumbnail
                      className="dealer-list__item"
                      thumbnail={dealer.thumbnail}
                      title={dealer.name}
                      subtitle={dealer.principalName ? `Dealer Principal: ${dealer.principalName}` : null}
                      href={dealer.dealerUrl}
                      target="_blank"
                      rel="nofollow"
                      button="Find out more"
                      onClick={() => {
                        gtmPush("audiFoundation", "supporterClick", `dealer_name = ${dealer.name}`, {dealer: dealer.name});
                      }}
                    />
                  </div>
                );
              })}
              {filteredDealers && filteredDealers.length === 0 && <p className="text-center">There are currently no participating dealers.</p>}
            </div>
          </div>}
            
          {(!match.params.state && dealers.hasLoaded) && <Map dealers={dealers.data} onStateClick={(s) => { history.push(`${PATH_SUPPORTERS}/${s}`); }} />}
          
          {pageData && pageData.hasLoaded && <Social share />}
        </div>
      </div>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(Supporters);
