import API from '../../functions/api';

const CATEGORIES_LOADED = 'categories/loaded';
const CATEGORIES_REQUESTED = 'categories/requested';

export function requestArticleCategories() {
    return (dispatch) => {
        dispatch({ type: CATEGORIES_REQUESTED });
        return API.get("/categories")
        .then(res => res.json())
        .then((data) => {
            dispatch({
                type: CATEGORIES_LOADED,
                data,
            });
        })
        .catch((error) => {
            console.log(`Couldn't get categories:`, error);
        });
    };
}

const defaultContentState = {
    hasLoaded: false,
    isFetching: false,
    data: null,
};

export default function articleCategories(state = defaultContentState, action) {
    switch (action.type) {
    case CATEGORIES_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case CATEGORIES_LOADED:
        return Object.assign({}, state, {
            hasLoaded: true,
            isFetching: false,
            data: action.data,
        });
    default:
        return state;
    }
}
