import API from '../../functions/api';

const PC_LIST_LOADED = 'partnercategories/list/loaded';
const PC_LIST_REQUESTED = 'partnercategories/list/requested';

export function requestPartnerCategories() {
    return (dispatch) => {
        dispatch({
            type: PC_LIST_REQUESTED,
        });

        return API.get('/partnerCategories')
        .then((res) => {
            dispatch({
                type: PC_LIST_LOADED,
                data: res.data,
            });
        })
        .catch((error) => {
            console.log(`Couldn't get partners:`, error);
        });
    };
}

const defaultContentState = {
    hasLoaded: false,
    isFetching: false,
    data: null,
};

export default function partnerCategoriesList(state = defaultContentState, action) {
    switch (action.type) {
    case PC_LIST_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case PC_LIST_LOADED:
        return Object.assign({}, state, {
            hasLoaded: true,
            isFetching: false,
            data: action.data,
        });
    default:
        return state;
    }
}
