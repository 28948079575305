import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M5.5,17.8 L5.5,22.5 L9.1,19.5 L16.6,3.5 C16.8,3.2 16.6,2.8 16.3,2.6 L13.9,1.5 C13.6,1.3 13.2,1.5 13,1.8 L5.5,17.8 Z"/>
          <path d="M11.8,4.2 L18.4,7.3 L15.8,12.8 M5.5,17.8 L9.1,19.5"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M12.5,36.8 L12.5,43.5 L17.9,39.5 L33.9,7.2 C34.1,6.7 33.9,6.1 33.5,5.9 L29.9,4.1 C29.4,3.9 28.8,4.1 28.6,4.5 L12.5,36.8 Z"/>
        <path d="M26.3,9 L35.7,13.7 L29.2,26.7 M12.5,36.8 L17.9,39.5"/>
      </g>
    </svg>
  );
};
