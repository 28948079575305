import validate from 'validate.js';
import { DateTime } from 'luxon';

// return only the first error message
validate.formatters.firstError = function(errors) {
  var errObj = {};
  if (errors.length > 0) {
    for (let i=0; i<errors.length; i++) {
      let field = errors[i].attribute;
      let msg = errors[i].options.message ? errors[i].options.message : errors[i].error;
      errObj[field] = msg;
    }
  }
  return errObj;
};

validate.validators.truthy = function(value, options, key, attributes) {
  if (!value) {
    return options.message || "This field is required";
  }
  return;
}

validate.validators.dob18 = function(value, options, key, attributes) {
  if (!value) {
    return "Please enter your date of birth";
  }
  if (value) {
    let d = DateTime.fromFormat(value, "dd/LL/yyyy");
    if(!d.isValid) {
      return options.message || "Please enter a valid date";
    }
    let now = DateTime.now();
    let diff = d.diff(now, "years"); 
    let diffObj = diff.toObject(); //console.log("date diff", value, diffObj);
    if (diffObj.years && diffObj.years > -18) {
      return options.message || "You must be 18 or over to enter this prize draw";
    }
  }
  return;
}

validate.validators.ticketLimit = function(value, options, key, attributes) {
  if (!value || value < 1) {
    return "Please enter a valid number of tickets";
  }
  if (options.maxPerPerson && options.maxPerPerson > 0 && value > options.maxPerPerson) {
    return `The maximum number of tickets per person is ${options.maxPerPerson}`;
  }
  if (options.maxAvailable && options.maxAvailable > 0 && value > options.maxAvailable) {
    return `The maximum number of tickets available is ${options.maxAvailable}`;
  }
}

validate.validators.stateHasPermit = function(value, options) {
  if (value && options.validStates && options.validStates.length > 0) {
    if (options.validStates.indexOf(value.toUpperCase()) === -1) {
      return `Sorry, ${value.toUpperCase()} residents are not eligible to enter`;
    }
  }
  return;
}

validate.validators.stripeCardNumber = function (value, options) {
  if (value) {
    var Stripe = null;
    if (typeof window !== 'undefined' && window.Stripe) {
      Stripe = window.Stripe;
    }
    let isValid = !!Stripe ? Stripe.card.validateCardNumber(value.replace(/ /g, '')) : false;
    if (!isValid) {
      return options.message || "Please enter a valid card number";
    }
  }
  return;
}

validate.validators.stripeCardExpiry = function (value, options) {
  if (value) {
    var Stripe = null;
    if (typeof window !== 'undefined' && window.Stripe) {
      Stripe = window.Stripe;
    }
    let isValid = !!Stripe ? Stripe.card.validateExpiry(value) : true;
    if (!isValid) {
      return options.message || "Please enter a valid expiry date";
    }
  }
  return;
}

validate.validators.stripeCVC = function (value, options) {
  if (value) {
    var Stripe = null;
    if (typeof window !== 'undefined' && window.Stripe) {
      Stripe = window.Stripe;
    }
    let isValid = !!Stripe ? Stripe.card.validateCVC(value.replace(/ /g, '')) : true;
    if (!isValid) {
      return options.message || "Please enter a valid CVC";
    }
  }
  return;
}


const defaultSchema = {
  firstName: {
    presence: { message: "Please enter your first name", allowEmpty: false },
    length: {maximum: 100, tooLong: "Too long (max 100 characters)"}
  },
  lastName: {
    presence: { message: "Please enter your last name", allowEmpty: false },
    length: {maximum: 100, tooLong: "Too long (max 100 characters)"}
  },
  email: {
    presence: { message: "Please enter your email address", allowEmpty: false },
    email: {message: "Please enter a valid email address"},
    length: {maximum: 200, tooLong: "Too long (max 200 characters)"}
  },
  phoneNumber: {
    presence: { message: "Please enter your mobile number", allowEmpty: false },
    format: {
      pattern: /^04.*$/,
      message: "Please enter a valid mobile number"
    }
  }
}

export {
  validate,
  defaultSchema
};
