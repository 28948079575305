// Sections
export const SECTION_CAPTIONED_CAROUSEL = 'SECTION_CAPTIONED_CAROUSEL';
export const SECTION_CAROUSEL = 'SECTION_CAROUSEL';
export const SECTION_FEATURES = 'SECTION_FEATURES';
export const SECTION_HTML = 'SECTION_HTML';
export const SECTION_IMAGE = 'SECTION_IMAGE';
export const SECTION_LEVELS = 'SECTION_LEVELS';
export const SECTION_OVERVIEW = 'SECTION_OVERVIEW';
export const SECTION_PROGRAM = 'SECTION_PROGRAM';
export const SECTION_QUOTES = 'SECTION_QUOTES';
export const SECTION_SHORTFORM = 'SECTION_SHORTFORM';
export const SECTION_SKILLS = 'SECTION_SKILLS';
export const SECTION_TABS = 'SECTION_TABS';
export const SECTION_VIDEO_CAROUSEL = 'SECTION_VIDEO_CAROUSEL';

// Components
export const COMPONENT_CAROUSEL_ITEM = 'COMPONENT_CAROUSEL_ITEM';
export const COMPONENT_FEATURE = 'COMPONENT_FEATURE';
export const COMPONENT_HTML = 'COMPONENT_HTML';
export const COMPONENT_IMAGE = 'COMPONENT_IMAGE';
export const COMPONENT_QUOTE = 'COMPONENT_QUOTE';
export const COMPONENT_TAB = 'COMPONENT_TAB';
export const COMPONENT_VIDEO = 'COMPONENT_VIDEO';

// Layout Width Options
export const WIDTH_CONSTRAINED = 'CONSTRAINED';
export const WIDTH_FULL = 'FULL';

// Layout Spacing Options
export const SPACE_NONE = 'NONE';
export const SPACE_SMALL = 'SMALL';
export const SPACE_MEDIUM = 'MEDIUM';
export const SPACE_LARGE = 'LARGE';

// Layout Type Options
export const TYPE_COLUMN_1 = 'COLUMN_1';
export const TYPE_COLUMN_2 = 'COLUMN_2';
export const TYPE_COLUMN_3 = 'COLUMN_3';
export const TYPE_COLUMN_4 = 'COLUMN_4';

// Column size Options
export const COLUMN_STYLE_PREFIX = 'col-md-';
export const COLUMN_PREFIX = 'COLUMN_';
