import React from 'react';
import classNames from 'classnames';
import ImageContent, { imageShape } from './ImageContent';

import {
    componentMissmatchWarning,
    layoutShape,
    layoutClasses,
} from './helpers';

import {
    SECTION_IMAGE,
    COMPONENT_IMAGE,
} from './constants';
import PropTypes from 'prop-types';
const { arrayOf, oneOf, shape, string } = PropTypes;

class ImageSection extends React.Component {

    static propTypes = {
        className: string,
        layout: layoutShape,
        components: arrayOf(shape({
            type: oneOf([COMPONENT_IMAGE]),
            content: shape({ image: imageShape }),
        })),
    };

    renderComponent(component, index) {
        const { type, content } = component;
        switch (type) {
        case COMPONENT_IMAGE:
            return (
                <ImageContent
                    key={index}
                    image={content.image}
                />
            );
        default:
            return componentMissmatchWarning(type, SECTION_IMAGE);
        }
    }

    render() {
        const { className, components, layout } = this.props;
        const classes = classNames('section--image', className, layoutClasses(layout));

        // Fail silently if no components
        if (!components) return null;

        return (
            <div className={classes}>
                {components.map(this.renderComponent)}
            </div>
        );
    }
}

export default ImageSection;
