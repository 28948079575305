import React from 'react';
import classnames from 'classnames';
import QuoteContent from './QuoteContent';
import Carousel from 'react-bootstrap/Carousel';

import {
    componentMissmatchWarning,
    layoutShape,
    layoutClasses,
} from './helpers';

import {
    SECTION_QUOTES,
    COMPONENT_QUOTE,
} from './constants';


// Slider settings
const settings = {
    autoplay: true,
    autoplaySpeed: 8000,
    arrows: true,
    dots: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const QuotesSection = ({components, layout, className}) => {
  if (!components) return null;
  
  const classes = classnames('section--quotes', className, layoutClasses(layout));

  const slides = components.map((component, i) => {
    if (component.type !== COMPONENT_QUOTE) {
      return null;
    }
    return (
      <div key={`quote-${i}`}>
          <QuoteContent {...component.content} />
      </div>
    );
  });

  if (components.length === 1) {
    return (
      <div className={classes}>
        <QuoteContent {...components[0].content} />
      </div>
    );
  }

  return (
    <div className={classes}>
      <Carousel variant="dark" indicators={false} interval={null}>
        {components.map((component,i) => {
          return (
            <Carousel.Item key={`quote${i}`}>
              <QuoteContent {...component.content} />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
}

export default QuotesSection;
