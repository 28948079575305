import API from '../../functions/api';

const PAGES_CONTENT_LOADED = 'pages/content/loaded';
const PAGES_CONTENT_REQUESTED = 'pages/content/requested';

export function requestPageData(slug, forceReload) {
    return (dispatch, getState) => {
        if (!slug || slug === undefined) {
            return null;
        }

        const pageData = getState().pages[slug];

        if (!forceReload && pageData && pageData.hasLoaded) {
            return Promise.resolve();
        }

        dispatch({
            type: PAGES_CONTENT_REQUESTED,
            slug: slug,
        });

        return API.get(`/pages/page_slug?page_slug=${slug}`)
        .then((res) => {
            // console.log("page res", res);
            dispatch({
                data: res.data,
                type: PAGES_CONTENT_LOADED,
                slug: slug,
                status: 200
            });
            return Promise.resolve();
        })
        .catch((error) => {
            // console.log("page error", error);
            dispatch({
                type: PAGES_CONTENT_LOADED,
                slug: slug,
                data: {},
                status: error.response.status
            });
            return Promise.resolve({status: error.response.status});
        });
    };
}

const defaultContentState = {};

export default function pages(state = defaultContentState, action) {
    switch (action.type) {
    case PAGES_CONTENT_REQUESTED:
        return Object.assign({}, state, {
            [action.slug]: {
                isFetching: true,
            },
        });
    case PAGES_CONTENT_LOADED:
        return Object.assign({}, state, {
            [action.slug]: {
                isFetching: false,
                hasLoaded: true,
                status: action.status,
                ...action.data,
            },
        });
    default:
        return state;
    }
}
