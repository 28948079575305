import React from 'react';
import Button from '../../audi-ui-components/Button';
import Loading from '../../components/Loading';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import ReCAPTCHA from "react-google-recaptcha";
import API from '../../functions/api';

class ReceiptForm extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      c: false,
      submitted: false,
      error: false
    };
  }
  
  validateForm = (values) => {
    return {};
  }
    
  submitForm = (values, formProps) => {
    if (!this.state.c) {
      return;
    }
    this.setState({error: false});
    var payload = Object.assign({}, values, {token: this.state.c});
    API.post("/receipts", payload)
      .then((result) => {
        this.setState({submitted: true});
        formProps.setSubmitting(false);
      }).catch((error) => {
        // console.error(error);
        let msg = "An error has occured.";
        if (error.response && error.response.data) {
          if (error.response.data.message) {
            msg = error.response.data.message;
          }
          if (error.response.data.modelState) {
            formProps.setErrors(error.response.data.modelState);
          }
        }
        this.setState({error: msg});
        formProps.setSubmitting(false);
      });
  }
    
  render() {
    return (
      <div className="form-section">
        <h4 className="aui-headline-4 mb-2 text-center">Recover your Receipts</h4>
        <p className="mb-3 text-center">Your contribution to the Audi Foundation helps make a difference in the lives of many everyday Australians. Enter your email address below to retrieve receipts from the last 12 months.</p>

        {this.state.submitted && <p className="mb-3 aui-color-text-green text-center">
          If we have found any entries for your email address from the last 12 months, we will send them to you shortly. Please don’t forget to check your email spam folder.
          <br /><br />
          <Button variant="text" onClick={() => {this.setState({submitted: false, c: false});}}>Try another email</Button>
        </p>}

        {!this.state.submitted && this.state.error && <div className="py-3 aui-color-text-red text-center">{this.state.error}</div>}
        
        {!this.state.submitted && <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{}}>
          {(formProps) => (
            <Form>
              <div className="row mb-3">
                <FormField name="email" label="Email address" trim wrapperClassName="col" formProps={formProps} />
                <div className="col-auto">
                  <Button type="submit" variant="primary" className="red" disabled={!this.state.c}>Submit</Button>
                </div>
              </div>
              <ReCAPTCHA sitekey={process.env.RAZZLE_GOOGLE_RECAPTCHA_KEY} onChange={(v) => { this.setState({c: v}); }} />
              {formProps.isSubmitting && <Loading type="overlay" />}
            </Form>
          )}
        </Formik>}

      </div>
    );
  }
}

export default ReceiptForm;
