import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import HtmlContent, { htmlShape } from './HtmlContent';
import ImageContent, { imageShape } from './ImageContent';
import VideoContent, { videoShape } from './VideoContent';
import QuoteContent, { quoteShape } from './QuoteContent';

import {
    componentMissmatchWarning,
    layoutShape,
    layoutClasses,
    columnClass,
} from './helpers';

import {
    SECTION_SHORTFORM,
    COMPONENT_HTML,
    COMPONENT_IMAGE,
    COMPONENT_QUOTE,
    COMPONENT_VIDEO,
} from './constants';

const { string, shape, object, arrayOf, oneOf, oneOfType } = PropTypes;

class ShortFormSection extends React.Component {

    static propTypes = {
        className: string,
        layout: layoutShape,
        columnSizing: object,
        components: arrayOf(shape({
            type: oneOf([
                COMPONENT_HTML,
                COMPONENT_IMAGE,
                COMPONENT_QUOTE,
                COMPONENT_VIDEO,
            ]).isRequired,
            component: oneOfType([
                shape({ html: htmlShape.isRequired }),
                shape({ image: imageShape.isRequired }),
                shape({ video: videoShape.isRequired }),
                shape({ quote: quoteShape.isRequired }),
            ]),
        })),
    };

    render() {
        const { className, components, layout, columnSizing } = this.props;
        // Fail silently if no components
        if (!components) return null;

        const classes = classnames('section--shortform', className, layoutClasses(layout));
        const htmlColumn = columnSizing && columnClass(columnSizing.html) ?
            columnClass(columnSizing.html) : 'col-medium-4';
        const contentColumn = columnSizing && columnClass(columnSizing.content) ?
            columnClass(columnSizing.content) : 'col-medium-8';
        const htmlClasses = classnames('shortform__content', htmlColumn);
        const contentClasses = classnames('shortform__content', contentColumn);
        const singleContentClasses = 'shortform__content col';

        function contentClassNames(main, index) {
            return classnames(main, {
                'shortform__content--left': (index === 0),
                'shortform__content--right': (index !== 0),
            });
        }

        const items = components.map((component, index) => {
            const { type, content } = component;
            switch (type) {
            case COMPONENT_HTML:
                return (
                    <HtmlContent
                        key={index}
                        className={contentClassNames(htmlClasses, index)}
                        html={content.html}
                    />
                );
            case COMPONENT_IMAGE:
                return (
                    <ImageContent
                        key={index}
                        className={contentClassNames(contentClasses, index)}
                        image={content.image}
                    />
                );
            case COMPONENT_VIDEO:
                return (
                    <VideoContent
                        key={index}
                        className={(components.length > 1) ?
                            contentClassNames(contentClasses, index)
                            : contentClassNames(singleContentClasses, index)
                        }
                        video={content.video}
                    />
                );
            case COMPONENT_QUOTE:
                return (
                    <QuoteContent
                        key={index}
                        className={contentClassNames(contentClasses, index)}
                        quote={content.quote}
                    />
                );
            default:
                componentMissmatchWarning(type, SECTION_SHORTFORM);
                return null;
            }
        });

        return (
            <div className={classes}>
                <div className="row">
                    {items}
                </div>
            </div>
        );
    }
}

export default ShortFormSection;
