import React from 'react';
import Helmet from 'react-helmet';
import { CMSSectionList } from '../components/cms';
import Loading from '../components/Loading';
import Hero from '../components/Hero';
import Social from '../components/Social';
import { gtmPageview, gtmPush } from '../functions/gtm';
import { joinTagsObject } from '../functions/stringHelpers';
import NotFound from './NotFound';

import { connect } from 'react-redux';
import { requestArticleData } from '../redux/content/articles';
const mapStateToProps = state => {
  return {
    articles: state.articles
  };
};
const mapDispatchToProps = dispatch => {
  return {
    requestArticleData: (slug, cb) => { dispatch(requestArticleData(slug, cb)); }
  }
};

class StorySingle extends React.Component {
  
  componentDidMount() {
    const { match, location, articles, requestArticleData } = this.props;
    if (!articles || !articles[match.params.slug]) {
      requestArticleData(match.params.slug, this.track);
    } else {
      this.track();
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  
  componentDidUpdate(prevProps) {
    const { articles, match } = this.props;
    if (!prevProps.articles[prevProps.match.params.slug] && articles[match.params.slug]) {
      this.track();
    }
  }
  
  track = () => {
    gtmPageview(location.pathname);
    const { articles, match } = this.props;
    let data = articles[match.params.slug];
    if (data && data.status === 200) {
      gtmPush("audiFoundation", "storyView", `story_name = ${data.title}`, {title: data.title, category: data.category, tags: joinTagsObject(data.tags), partner: data.partner});
    }
  }

  render() {
    const { articles, match } = this.props;
    const data = (articles && articles[match.params.slug]) ? articles[match.params.slug] : {};
    return (
      <div className="page story-single">
        {data?.status === 200 && <Helmet>
          <title>{`${data.title} | Audi Foundation`}</title>
          <meta name="description" content={data.description} />
          <meta property="og:title" content={data.title} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={data.heroImage} />
          <meta property="og:description" content={data.description} />
        </Helmet>}
        
        {data?.heroImage && <Hero heroImage={data.heroImage} />}
        
        <div className="page-inner">
          <div className="content-wrapper">
            {(!data.hasLoaded) && <Loading />}
            
            {data?.status > 200 && <NotFound />}
            
            {data?.status === 200 && <div className="page-intro mt-0">
              <h1 className="aui-headline-2 mb-3"><b>{data.title}</b></h1>
              <p>{data.shortDescription}</p>
            </div>}
            
            {data?.sections && <CMSSectionList sections={data.sections} />}
            
            {data?.status === 200 && <Social share={{facebook: true, story: {category: data.cateogry, tags: data.tags, partner: data.partner}}} />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StorySingle);
