/* eslint-disable no-console, react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
    // Layout width options
    WIDTH_FULL,
    WIDTH_CONSTRAINED,
    // Layout spacing options
    SPACE_NONE,
    SPACE_SMALL,
    SPACE_MEDIUM,
    SPACE_LARGE,
    // Layout type options
    TYPE_COLUMN_1,
    TYPE_COLUMN_2,
    TYPE_COLUMN_3,
    TYPE_COLUMN_4,
    // Column sizes
    COLUMN_PREFIX,
    COLUMN_STYLE_PREFIX,
} from './constants';

const { shape, oneOf } = PropTypes;

// Layout shape definition
export const layoutShape = shape({
    spacing: oneOf([
        SPACE_NONE,
        SPACE_SMALL,
        SPACE_MEDIUM,
        SPACE_LARGE,
    ]),
    type: oneOf([
        '', // Layout type isn't required so accept empty values
        TYPE_COLUMN_1,
        TYPE_COLUMN_2,
        TYPE_COLUMN_3,
        TYPE_COLUMN_4,
    ]),
    width: oneOf([
        WIDTH_FULL,
        WIDTH_CONSTRAINED,
    ]),
});

export function sectionMissmatchWarning(type, componentName, showWarnings) {
    if (showWarnings) {
        console.error(`Section type missmatch: unexpected '${type}' in ${componentName}`);
        return (
            <div key={`warn(${type}__${componentName})`} style={{ outline: '2px solid red' }}>
                <p>Unknown section type '{type}' in '{componentName}'</p>
            </div>
        );
    }
    return null;
}

export function componentMissmatchWarning(type, section, showWarnings) {
    if (showWarnings) {
        console.error(`Component type missmatch: unexpected '${type}' in ${section}`);
        return (
            <div key={`warn(${type}__${section})`} style={{ outline: '2px solid red' }}>
                <p>Unknown Component type {type} in {section}</p>
            </div>
        );
    }
    return null;
}

export function layoutClasses(layout) {
    if (!layout) return null;
    const { spacing, type, width } = layout;
    return classnames({
        // Spacing
        'mb-5': spacing === SPACE_LARGE,
        'mb-3': spacing === SPACE_MEDIUM,
        'mb-2': spacing === SPACE_SMALL,
        // Type
        'type-column-1': type === TYPE_COLUMN_1,
        'type-column-2': type === TYPE_COLUMN_2,
        'type-column-3': type === TYPE_COLUMN_3,
        'type-column-4': type === TYPE_COLUMN_4,
    });
}


export function columnClass(columnWidth) {
    if (!columnWidth || columnWidth.indexOf(COLUMN_PREFIX)) return null;

    return `${COLUMN_STYLE_PREFIX}${columnWidth.replace(COLUMN_PREFIX, '')}`;
}

export function isExternal(url) {
    if (url.indexOf('mailto:') === 0) return true;
    return /https?:\/\/((?:[\w\d]+\.)+[\w\d]{2,})/i.test(url);
}

const startsWithSingleSlash = (url) => /^\/[\w\d]+/.test(url);
export function isInternalLink(url) {
    return startsWithSingleSlash(url);
}

export function unOrphan(txt){

    let splits = txt.split("</p>");

    const oldChar = " ";
    const newChar = "&nbsp;";

    splits = splits.map(t => {
        if(t.length <= 15) return t;

        const trim = t.substr(0, t.length - 2);
        const _lastIndex = trim.lastIndexOf(oldChar);
        if( _lastIndex > 0){
            return t.substr(0, _lastIndex) + newChar + t.substr(_lastIndex + 1);
        }else {
            return t;
        }
    });
    return splits.join("</p>");

};
