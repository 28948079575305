import API from '../../functions/api';

const BOARDMEMBERS_LOADED = 'boardmembers/loaded';
const BOARDMEMBERS_REQUESTED = 'boardmembers/requested';

export function requestBoardMembers() {
    return (dispatch) => {
        dispatch({
            type: BOARDMEMBERS_REQUESTED,
        });

        return API.get('/boardmembers')
        .then((res) => {
            dispatch({
                type: BOARDMEMBERS_LOADED,
                data: res.data,
            });
        })
        .catch((error) => {
            console.log(`Couldn't get board members:`, error);
        });
    };
}

const defaultContentState = {
    hasLoaded: false,
    isFetching: false,
    data: null,
};

export default function boardMembers(state = defaultContentState, action) {
    switch (action.type) {
    case BOARDMEMBERS_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case BOARDMEMBERS_LOADED:
        return Object.assign({}, state, {
            hasLoaded: true,
            isFetching: false,
            data: action.data,
        });
    default:
        return state;
    }
}
