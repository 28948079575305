import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import Button from '../audi-ui-components/Button';

class Thumbnail extends React.Component {
    static propTypes = {
        button: PropTypes.string,
        category: PropTypes.string,
        children: PropTypes.node,
        className: PropTypes.string,
        description: PropTypes.string,
        href: PropTypes.string,
        onClick: PropTypes.func,
        tallHero: PropTypes.bool,
        target: PropTypes.string,
        subtitle: PropTypes.string,
        thumbnail: PropTypes.string,
        title: PropTypes.string,
        to: PropTypes.string,
    };

    handleClick = () => {
      if (typeof this.props.onClick === 'function') {
        this.props.onClick();
      }
    }

    render() {
        const {
            button,
            category,
            children,
            className,
            description,
            href,
            subtitle,
            target,
            rel,
            thumbnail,
            thumbnailNode,
            title,
            to,
        } = this.props;

        const heroStyle = { backgroundImage: `url(${thumbnail})` };

        const thumbnailClasses = classnames('thumbnail', className, {
            'thumbnail--no-hero': (!thumbnail && !thumbnailNode),
        });

        const thumbnailBody = (
            <div>
                {thumbnail &&
                    <div className="thumbnail__hero" style={heroStyle} />
                }
                {thumbnailNode && <div className="thumbnail__hero node">{thumbnailNode}</div>}
                <div className="thumbnail__inner">
                    {category &&
                        <p className="thumbnail__category mb-1">
                            {category}
                        </p>
                    }
                    <div className="thumbnail__header mb-3">
                        {title &&
                            <h4 className="aui-headline-4 mb-2"><b>{title}</b></h4>
                        }
                        {subtitle &&
                            <p className="thumbnail__subtitle">{subtitle}</p>
                        }
                    </div>
                    {description &&
                        <div className="thumbnail__description my-2" dangerouslySetInnerHTML={{ __html: description }} />
                    }
                    {button &&
                        <Button variant="text" onClick={() => {}}>
                            {button}
                        </Button>
                    }
                    {children}
                </div>
            </div>
        );

        if (to) {
            return (
                <div className={thumbnailClasses}>
                    <Link onClick={this.handleClick} to={to}>
                        {thumbnailBody}
                    </Link>
                </div>
            );
        }

        if (href) {
            return (
                <div className={thumbnailClasses}>
                    <a onClick={this.handleClick} href={href} target={target} rel={rel}>
                        {thumbnailBody}
                    </a>
                </div>
            );
        }

        return (
            <div onClick={this.handleClick} className={thumbnailClasses}>
                {thumbnailBody}
            </div>
        );
    }
}

export default Thumbnail;
