import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from '../components/Loading';
import Hero from '../components/Hero';
import Button from '../audi-ui-components/Button';
import { CMSSectionList } from '../components/cms';
import { gtmPageview } from '../functions/gtm';

import {
  PATH_PARTNERS
} from '../constants';

import { connect } from 'react-redux';
import { requestPartners } from '../redux/content/partnersList';
import { requestPageData } from '../redux/content/pages';
const mapStateToProps = state => {
  return {
    hasLoaded: state.pages.partners ? state.pages.partners.hasLoaded : false,
    pageData: state.pages.partners,
    partners: state.partnersList.data,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    requestPageData: (slug) => { dispatch(requestPageData(slug)); },
    requestPartners: () => { dispatch(requestPartners()); }
  }
}

class Partners extends React.Component {
  
  componentDidMount() {
    gtmPageview(this.props.location.pathname);
    this.props.requestPageData('partners');
    if (!this.props.partners) {
      this.props.requestPartners();
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  render() {
    const { pageData, partners, hasLoaded } = this.props;
    return (
      <div className="page partners">
        <Helmet>
          <title>Partners | Audi Foundation</title>
          <meta name="description" content="Audi Foundation Partners" />
          <meta property="og:site_name" content="Audi Foundation Partners" />
        </Helmet>
        
        {pageData && pageData.heroImage && <Hero heroImage={pageData.heroImage} />}
        
        <div className="page-inner">
          <div className="content-wrapper">
            {pageData && pageData.sections && <CMSSectionList sections={pageData.sections} />}
          </div>
        
          <div className="content-wrapper">
            {(!pageData || !hasLoaded || !partners) && <Loading />}
          </div>
        
          <div className="mb-4">
            <div className="partners-list">
              {partners && partners.map((partner,i) => {
                return (
                  <div key={partner.partnerId} className="partner-preview">
                    <Link to={`${PATH_PARTNERS}/${partner.slug}`}>
                      <div className="row g-0">
                        <div className="col-large-8 col-small-6 partner-preview__col-hero">
                          {partner.thumbnail &&
                            <div className="partner-preview__hero" style={{ backgroundImage: `url(${partner.thumbnail})` }} />
                          }
                        </div>
                        <div className="col-large-4 col-small-6 partner-preview__col-text">
                          <div className="content-wrapper">
                            {partner.logo &&
                              <img src={partner.logo} alt={partner.title} className="partner__logo"/>
                            }
                            <h5 className="aui-headline-4 mb-3 mt-1 pt-2">{partner.title}</h5>
                            <p className="partner-preview__blurb mb-2">{partner.description}</p>
                            <Button variant="text" onClick={()=>{}}>Find out more</Button>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Partners);
