import React from 'react';
import PropTypes from 'prop-types';
import bezierEasing from 'bezier-easing';

const SIZE = 48;
const RADIUS = 22;
const STROKE_WIDTH = 3;
const LOOP_ANIMATION_DURATION = 1.5;
const LOOP_ANIMATION = [
  {
    dashLength: 0,
    dashOffset: 0,
    rotate: 0,
  },{
    dashLength: 0.65,
    dashOffset: -1,
    rotate: 360,
  }
];
// Limits a value to x >= min and x <= max.
function limit(x, min, max) {
  if (x > max) x = max;
  if (x < min) x = min;
  return x;
}

class Spinner extends React.Component {

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();
    this.groupRef = React.createRef();
    this.pathRef = React.createRef();
    this.state = {
      loopStart: null,
      size: SIZE,
      radius: RADIUS
    };
    this._easing = bezierEasing(0.75, 0.02, 0.5, 1);
    this._easingDasharray = bezierEasing(0.4, 0, 0, 1);
  }

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    this.resize();
    if (this.props.isContinuous) {
      this.pathRef.current.style.strokeOpacity = 1;
      window.requestAnimationFrame(timestamp => this._animateLoop(timestamp));
    } else {
      this._animateProgress(this.props.progress);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isContinuous) {
      this._animateProgress(this.props.progress);
    }
  }

  getSpinnerClass = () => {
    let c = "aui-spinner";
    if ((this.props.progress || this.props.progress === 0) && !this.props.isContinuous) {
      c += " aui-spinner--progress";
      var progress = limit(this.props.progress, 0, 1);
      if (progress === 0) {
        c += " is-pending";
      } else if (progress === 1) {
        c += " is-complete";
      } else {
        c += " is-loading";
      }
    }
    if (this.props.isContinuous) {
      c += " aui-spinner--continuous"
    }
    if (this.props.theme) { c += ` aui-theme-${this.props.theme}`; }
    return c;
  }

  resize = () => {
    const size = this.containerRef.current.offsetWidth;
    const radius = (size - STROKE_WIDTH) / 2;
    this.setState({size, radius});
  }

  _animateLoop = (timestamp) => {
    if (this.pathRef && this.pathRef.current) {
      if (!this.state.loopStart) { this.setState({loopStart: timestamp}); }

      let progress = (timestamp - this.state.loopStart) / 1000;
      let progressRatio = (progress / LOOP_ANIMATION_DURATION);
      if (progressRatio > 1 || progressRatio === 0) {
        progress = 0;
        progressRatio = 0;
        this.setState({loopStart: timestamp});
      }
      var ease = this._easing(progressRatio);
      var _dashLengthRatio = LOOP_ANIMATION[0].dashLength + (LOOP_ANIMATION[1].dashLength - LOOP_ANIMATION[0].dashLength) * this._easingDasharray(progressRatio);
      var _dashOffsetRatio = LOOP_ANIMATION[0].dashOffset + (LOOP_ANIMATION[1].dashOffset - LOOP_ANIMATION[0].dashOffset) * ease;
      var _rotate = LOOP_ANIMATION[1].rotate * ease;
      var pathLength = 2 * Math.PI * this.state.radius;

      var dashLength = pathLength * _dashLengthRatio;
      var dashOffset = pathLength * _dashOffsetRatio;

      this.pathRef.current.style.strokeDasharray = `${dashLength}, ${pathLength}`;
      this.pathRef.current.style.strokeDashoffset = `${dashOffset}`;
      this.groupRef.current.setAttributeNS(null, 'transform', `rotate(${_rotate})`);

      requestAnimationFrame(timestamp => this._animateLoop(timestamp));
    }
  }

  _animateProgress = (ratio) => {
    if (this.pathRef && this.pathRef.current) {
      ratio = limit(ratio, 0, 1);
      var pathLength = 2 * Math.PI * this.state.radius;
      this.pathRef.current.style.strokeDasharray = ratio === 1 ? 'none' : `${ratio * pathLength}, ${pathLength}`;
      this.pathRef.current.style.strokeOpacity = ratio === 0 ? 0 : 1;
    }
  }

  render() {
    const {size, radius} = this.state;
    const value = Math.round(this.props.progress * 100);
    return (
      <div ref={this.containerRef} className={this.getSpinnerClass()}>
        {!this.props.isContinuous && <span className="aui-spinner__value">{`${value}`}</span>}
        <svg className="aui-spinner__svg" viewBox={`${size / -2} ${size / -2} ${size} ${size}`}>
          <g className="aui-spinner__group">
            <g className="aui-spinner__group-base">
              <circle className="aui-spinner__path" cx="0" cy="0" r={radius} />
            </g>
            <g ref={this.groupRef} className="aui-spinner__group-progress">
              <circle ref={this.pathRef} className="aui-spinner__path aui-spinner__path--progress" cx="0" cy="0" r={radius} transform="rotate(-90)" />
            </g>
          </g>
        </svg>
      </div>
    );
  }

}

Spinner.propTypes = {
  progress: PropTypes.number,
  isContinuous: PropTypes.bool,
  theme: PropTypes.oneOf(['light'])
};

export default Spinner;
