import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
//
class Checkbox extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFocused: false
    };
  }

  handleChange = (e) => {
    if (typeof this.props.onChange === 'function') {
      this.props.onChange(this.props.value, e);
    }
  }

  handleFocus = (e) => {
    this.setState({isFocused: true});
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  }

  handleBlur = (e) => {
    this.setState({isFocused: false});
    if (typeof this.props.onBlur === 'function') {
      this.props.onBlur(e);
    }
  }

  render() {
    const {label, value, name, checked, disabled, isValid, isInvalid, invalidMessage, isDropdownOption, theme} = this.props;
    return(
      <label onFocus={this.handleFocus} onBlur={this.handleBlur} style={this.props.style} className={classnames(
        "aui-checkbox", `${this.props.className}`,
        {
          "is-checked": !!checked,
          "is-focused": this.state.isFocused,
          "is-disabled": disabled,
          "is-invalid": isInvalid,
          "aui-checkbox--dropdown-option": isDropdownOption,
          [`aui-theme-${theme}`]: theme
        }
      )}>
        <input className="aui-checkbox__input" type="checkbox" name={name} value={value} checked={!!checked} disabled={disabled} onChange={this.handleChange} />
        {label && <span className="aui-checkbox__label">{label}</span>}
        <span className="aui-checkbox__box"><span className="aui-checkbox__tick"></span></span>
        {isInvalid && invalidMessage && <span className="aui-checkbox__error">{invalidMessage}</span>}
      </label>
    );
  }

}

Checkbox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.any,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  isDropdownOption: PropTypes.bool,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_checkbox-themes.scss
};

Checkbox.defaultProps = {
  value: false,
  onChange: () => {},
  style: {},
  className: ''
}

export default Checkbox;
