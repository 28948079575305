import API from '../../functions/api';

const PRIZE_DRAW_CONTENT_LOADED = 'prizedraw/content/loaded';
const PRIZE_DRAW_CONTENT_REQUESTED = 'prizedraw/content/requested';

export function requestDrawData() {
    return (dispatch) => {
        dispatch({
            type: PRIZE_DRAW_CONTENT_REQUESTED,
        });

        return API.get('/draw')
        .then((res) => {
            // hardcoded video
            // res.data.video = "https://cdn01.audiproduction.com.au/cms/media/6683/voga0193_audi_landing-page_1875x625_v2.mp4";
            let _prizes = res.data.prizes.sort(function(a,b){ return a.number - b.number; });
            res.data.prizes = _prizes;
            dispatch({
                type: PRIZE_DRAW_CONTENT_LOADED,
                data: res.data,
            });
        })
        .catch((error) => {
            console.log('Couldn\'t get prize draw data:', error);
            dispatch({
                type: PRIZE_DRAW_CONTENT_LOADED,
                data: {},
            });
        });
    };
}

const defaultContentState = {};

export default function prizedraw(state = defaultContentState, action) {
    switch (action.type) {
    case PRIZE_DRAW_CONTENT_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case PRIZE_DRAW_CONTENT_LOADED:
        return Object.assign({}, state, {
            isFetching: false,
            hasLoaded: true,
            ...action.data,
        });
    default:
        return state;
    }
}
