import API from '../../functions/api';
import { DateTime } from 'luxon';

const ARTICLES_LIST_LOADED = 'articles/list/loaded';
const ARTICLES_LIST_REQUESTED = 'articles/list/requested';

export function requestArticles(options) {
    return (dispatch) => {
        dispatch({
            type: ARTICLES_LIST_REQUESTED,
        });

        const { pageIndex, tag } = options;
        
        const pagesize = 12;
        
        let uri = '/articles';
        if (tag !== 'all') {
            uri = `/articles/tags/${tag}`
        }
        uri += `?pageSize=${pagesize}&$count=true`;
        if (pageIndex > 0) {
            uri += `&$skip=${pageIndex * pagesize}`;
        }

        return API.get(uri)
        .then((res) => {
            dispatch({
                type: ARTICLES_LIST_LOADED,
                data: res.data,
                pageIndex: pageIndex + 1,
                tag: tag || 'all',
            });
        })
        .catch((error) => {
            console.log(`Couldn't get aricles:`, error);
        });
    };
}

const defaultContentState = {
    isFetching: false
};

export default function articlesList(state = defaultContentState, action) {
    switch (action.type) {
    case ARTICLES_LIST_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    
    case ARTICLES_LIST_LOADED:

        const newArtices = state[action.tag] ? state[action.tag].articles.concat(action.data.items) : action.data.items;
        const areMore = action.data.nextPageLink == undefined ? false: true;

        //const _d = moment(newArtices[0].updateDate).toDate();

        // First sort by articleID
        newArtices.sort(function(a, b){
            if(a.articleId < b.articleId  ){
                return 1
            }
            return -1;
        });

         // sort by updateDate
        // then sort by date. If the dates are all the same then there is no effect
         newArtices.sort(function(a, b) {
             const _a = DateTime.fromISO(a.updateDate).valueOf();
             const _b = DateTime.fromISO(b.updateDate).valueOf();
             if (_a < _b) return 1;
             if (_a > _b) return -1;
             return 0;
         });


        return Object.assign({}, state, {
            [action.tag]: {
                articles: newArtices,
                pageIndex: action.pageIndex,
                canLoadMore: areMore,
            },
        });
    default:
        return state;
    }
}
