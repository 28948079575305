import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../audi-ui-components/Button';

import {
  PATH_STORIES_SINGLE,
  PATH_STORIES_TAG,
  PATH_PARTNERS,
  PATH_SUPPORTERS,
  PATH_ABOUT,
  PATH_FAQ
} from '../constants';

const SearchResult = ({ documentType, slug, state, name, shortDescription, tags, thumbnail, searchId, closeSearch }) => {
  
  let link = false;
  let tagLinks = false;
  
  switch(documentType) {
    case "article":
      link = `${PATH_STORIES_SINGLE}/${slug}`;
      tagLinks = tags.map(tag => {
        return (<Link to={`${PATH_STORIES_TAG}/${tag.slug}`} key={tag.slug} className="aui-textlink">{tag.name}</Link>)
      });
      break;

    case "partner":
      link = `${PATH_PARTNERS}/${slug}`;
      break;
      
    case "boardMember":
    case "boardMembers":
      link = PATH_ABOUT;
      break;

    case "dealer":
      link = `${PATH_SUPPORTERS}/${state}`;
      break;
      
    case "fAQ":
      link = `${PATH_FAQ}/${slug}`;
      break;
      
    case "contentPage":
    case "donate":
      link = slug === 'home' ? "/" : `/${slug}`;
  }
  
  if (!link) {
    return null;
  }
  
  if (tagLinks && tagLinks.length > 0) {
    return (
      <div className="result">
        <div className="result__text">
          <Link to={link} onClick={closeSearch}><p className="aui-headline-4 mb-2"><b>{name}</b></p></Link>
          {shortDescription && <div className="mb-2">{shortDescription}</div>}
          <div className="tags aui-color-text-light"><span>Tags: </span> {tagLinks}</div>
          <Button variant="text" theme="light" to={link}>More</Button>
        </div>
        <div className="result__image-wrapper">
          {thumbnail && <Link to={link} className="result__image" style={{backgroundImage: `url("${thumbnail}")`}} />}
        </div>
      </div>
    );
  }
  
  return (
    <Link to={link} onClick={closeSearch} className="result">
      <div className="result__text">
        <p className="aui-headline-4 mb-2"><b>{name}</b></p>
        {shortDescription && <div className="mb-2">{shortDescription}</div>}
        <Button variant="text" theme="light">More</Button>
      </div>
      <div className="result__image-wrapper">
        {thumbnail && <div className="result__image" style={{backgroundImage: `url("${thumbnail}")`}} />}
      </div>
    </Link>
  );
}

export default SearchResult;
