const NotFound = ({ isPage }) => {
  return (
    <div className="page notfound">
      <div className={isPage ? "page-inner" : ""}>
        <div className={isPage ? "content-wrapper" : ""}>404 Page not found</div>
      </div>
    </div>
  );
}
export default NotFound;
