import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import NavBar from '../audi-ui-components/NavBar';
import Logo from './Logo';
import AudiRingsLogo from './AudiRingsLogo';
import IconSearch from './icons/Search';
import _findIndex from 'lodash/findIndex';
import Search from './Search';

import {
  PATH_ABOUT,
  PATH_DONATE,
  PATH_HOME,
  PATH_PARTNERS,
  PATH_STORIES,
  PATH_SUBSCRIBE,
  PATH_PRIZE_DRAWS,
  PATH_RAFFLE,
  PATH_SUPPORTERS,
  FEATURE_TOGGLE,
} from '../constants';

const navigationLinks = [
  {
    to: PATH_HOME,
    exact: true,
    text: 'Home',
    className: 'home'
  }, {
    to: PATH_PARTNERS,
    text: 'Partners',
  }, {
    to: PATH_STORIES,
    text: 'Impact',
  },{
    to: PATH_SUPPORTERS,
    text: 'Supporters',
  }, {
    to: PATH_ABOUT,
    text: 'About',
  }, {
    to: PATH_SUBSCRIBE,
    text: 'Subscribe',
    enabled: FEATURE_TOGGLE.SUBSCRIBE.nav,
  }, {
    to: PATH_PRIZE_DRAWS,
    text: 'Prize Draw',
    enabled: FEATURE_TOGGLE.RAFFLE.nav,
  }, {
    to: PATH_DONATE,
    className: 'donate',
    text: 'Donate',
    enabled: FEATURE_TOGGLE.DONATE.nav,
  },
];

const Header = ({location}) => {
  
  const [activeNav, setActiveNav] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchClosing, setSearchClosing] = useState(false);
  
  useEffect(() => {
    let i = _findIndex(navigationLinks, function(o) {
      if (o.exact) {
        return o.to === location.pathname;
      }
      return location.pathname.indexOf(o.to) === 0;
    });
    if (i === -1) {
      setActiveNav(null);
    } else {
      setActiveNav(i)
    }
    setNavOpen(false);
  }, [location]);
  
  const openSearch = () => {
    setNavOpen(false);
    setSearchOpen(true);
    document.body.classList.add("search-open");
  }
  
  const closeSearch = () => {
    setSearchClosing(true);
    setTimeout(() => {
      setSearchClosing(false);
      setSearchOpen(false);
    }, 400);
    document.body.classList.remove("search-open");
  }
  
  const toggleNav = () => {
    if (!navOpen) {
      closeSearch();
    }
    setNavOpen(!navOpen);
  }
  
  return (
    <header className={classnames("page-header", {"nav-open": navOpen, "search-open": searchOpen, "search-closed": !searchOpen, "search-closing": searchClosing})}>
      <div className="content-wrapper">
        
        <div className="page-header__top d-flex justify-content-between">
          <div className="d-flex align-items-center d-small-none">
            <button className="btn-menu me-3" onClick={toggleNav}>
              <span className="visually-hidden">menu</span>
              <span className="line top" /><span className="line mid" /><span className="line bot" />
            </button>
            <button className="btn-search" onClick={openSearch}>
              <span className="visually-hidden">search</span>
              <IconSearch small />
            </button>
          </div>
          <div className="text-end text-small-start">
            <Link to="/" className="logo"><h1><span className="visually-hidden">Audi Foundation</span><Logo /></h1></Link>
          </div>
          <div className="text-end d-none d-small-block">
            <a className="rings" href="https://www.audi.com.au/au/web/en.html" target="_blank" rel="noreferrer"><AudiRingsLogo /></a>
          </div>
        </div>
        
        <div className={classnames("page-header__nav", {"nav-open": navOpen})}>
          <NavBar actions={navigationLinks} activeIndex={activeNav} />
          <button className="btn-search d-none d-small-flex" onClick={openSearch}>
            <span className="d-none d-medium-inline-block">Search</span> <IconSearch small />
          </button>
        </div>
        
      </div>
      
      <Search open={searchOpen} closing={searchClosing} closeSearch={closeSearch} />
    </header>
  );
}

export default Header;
