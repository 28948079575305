import React from 'react';
import classnames from 'classnames';
import TextField from '../audi-ui-components/TextField';
import Button from '../audi-ui-components/Button';
import IconSearch from '../audi-ui-components/icons/Search';
import IconCancel from '../audi-ui-components/icons/Cancel';
import Loading from './Loading';
import SearchResult from './SearchResult';
import API from '../functions/api';
import { gtmPush } from '../functions/gtm';

class Search extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      hasLoaded: false,
      searchText: "",
      results: [],
      count: 0,
      page: 1,
    };
    this.pagesize = 12;
    this.searchTimeout = null;
    this.inputRef = React.createRef();
  }
  
  componentDidUpdate(prevProps) {
    if (this.props.open && !prevProps.open) {
      this.inputRef.current && this.inputRef.current.focus();
    }
    if (!this.props.open && prevProps.open) {
      this.inputRef.current && this.inputRef.current.blur();
    }
  }
  
  getSearchResults = (_page, _text) => {
    // console.log("getSearchResults", _page, _text);
    this.setState({loading: true});
    let words = _text || this.state.searchText;
    let p = _page || this.state.page;
    let skip = p > 1 ? p * this.pagesize : 0;
    API.get(
      `/searchresults?keyword=${words}&pageSize=${this.pagesize}&$skip=${skip}&$count=true`
    ).then((res) => {
      // console.log(res.data);
      let newResults = res.data.items;
      if (p > 1) {
        newResults = [...this.state.results, ...res.data.items];
      }
      this.setState({
        count: res.data.count || 0,
        page: p,
        results: newResults,
        loading: false,
        hasLoaded: true
      });
    }).catch((error) => {
      console.log(`Couldn't get search results:`, error);
      this.setState({loading: false});
    });
  }
  
  clearSearch = () => {
    this.setState({
      count: 0,
      page: 1,
      results: [],
      hasLoaded: false
    });
  }
  
  handleChange = (v) => {
    // console.log("handleChange", v);
    this.setState({searchText: v});
    clearTimeout(this.searchTimeout);
    if (v && v.length > 2) {
      this.searchTimeout = setTimeout(() => {
        gtmPush("audiFoundation", "searchCompleted", `search_text = ${v}`, {query: v});
        this.getSearchResults(1, v);
      }, 1000);
    } else {
      this.clearSearch();
    }
  }
  
  render() {
    const { results, count, loading, hasLoaded } = this.state;
    const hasMore = results && results.length > 0 && results.length < count;
    return (
      <div className={classnames("search", {"open": this.props.open, "closing": this.props.closing})}>
        <div className="content-wrapper">
          
          <div className="py-3 search__top">
            <div className="row align-items-end">
              <div className="col">
                <TextField name="searchText"
                  value={this.state.searchText}
                  onChange={this.handleChange}
                  icon={<IconSearch small />}
                  label="" floatingLabel={false}
                  placeholder="type to search"
                  theme="light"
                  ref={this.inputRef}
                />
              </div>
              <div className="col-auto">
                <Button onClick={this.props.closeSearch} icon={<IconCancel large />} variant="icon" theme="light">close</Button>
              </div>
            </div>
            {(count > 0) && <p className="">{results.length} of {count} results</p>}
          </div>
          
          <div className="search__results">
            <div className="inner">
              {results && results.map((res) => (<SearchResult {...res} closeSearch={this.props.closeSearch} key={`res${res.searchId}`} />))}
              {loading && <Loading />}
              {hasLoaded && !loading && count === 0 && <p>No results found</p>}
              {hasMore && <div className="my-3">
                <Button variant="secondary" theme="light" onClick={() => { this.getSearchResults(this.state.page + 1); }}>Load more</Button>
              </div>}
            </div>
          </div>
          
        </div>
      </div>
    );
  }
};

export default Search;
