import React from 'react';
import PropTypes from 'prop-types';
// import classnames from 'classnames';
import queryString from 'query-string';
import VideoJS from '../VideoJS';

// import VideoVimeoContent from './VideoVimeoContent';
// import VideoYoutubeContent from './VideoYoutubeContent';

export const videoShape = PropTypes.shape({
  cover: PropTypes.string,
  playerVars: PropTypes.object,
  src: PropTypes.string,
  vimeoId: PropTypes.string,
  youtubeId: PropTypes.string,
});

const EmbedWrapper = ({children}) => {
  return (
    <div className="media-embed__background">
      <div className="media-embed__width">
        <div className="media-embed__height">
          {children}
        </div>
      </div>
    </div>
  );
}

class VideoContent extends React.Component {

  constructor(props) {
    super(props);
    const { video } = props;

    // Get ids of videos from props (explicit ids or within the src)
    const vimeoId = video.vimeoId || this.getVimeoIDFromURL(video.src);
    const youtubeId = video.youtubeId || this.getYoutubeIDFromURL(video.src);

    if (vimeoId) {
      this.type = 'vimeo';
      this.id = vimeoId;
    } else if (youtubeId) {
      this.type = 'youtube';
      this.id = youtubeId;
    } else {
      let srcWithoutQuery = video.src.split('?')[0];
      let ext = srcWithoutQuery.split('.').pop().toLowerCase();
      if (ext === "mp4" || ext === "m4v") {
        this.type = 'mp4';
      }
      if (ext === "m4v") {
        this.type = 'm4v';
      }
    }
  }

  getVimeoIDFromURL = (src) => {
    if (!src) return false;
    const regExp = /https?:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
    const match = src.match(regExp);
    if (!match || !match[2]) return false;
    return match[2];
  }

  getYoutubeIDFromURL = (src) => {
    if (!src) return false;
    const url = src.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
    if (url[2] === undefined) return false;
    return url[2].split(/[^0-9a-z_\-]/i)[0];
  }

  render() {
    const { className, video: { image, playerVars, src }, onComplete, onProgress } = this.props;
    if (this.type === 'mp4' || this.type === 'm4v') {
      let srcSplit = src.split('?');
      let q = queryString.parse(srcSplit[1]);
      let videoJsOptions = {
        autoplay: q.autoplay ? Boolean(q.autoplay) : false,
        muted: q.autoplay ? Boolean(q.autoplay) : false,
        loop: q.loop ? Boolean(q.loop) : false,
        controls: true,
        fluid: true,
        sources: [{
          src: srcSplit[0],
          type: 'video/mp4'
        }]
      };
      if (image && image.src) {
        let p = image.src;
        if (image.src.indexOf("{") === 0) {
          // it's a JSON string for some reason
          let img = "";
          try {
            img = JSON.parse(image.src);
          } catch(err){
            // it's a badly formed json string for some reason
            // console.log(err);
            let s = image.src.split("'");
            p = s[1];
          };
          if (img) {
            p = img.src;
          }
        }
        videoJsOptions.poster = p;
      }
      if (this.type === 'mp4'){
        return (
          <div className={className}>
            <VideoJS options={videoJsOptions} />
          </div>
        );
      }
      if (this.type === 'm4v') {
        return (
          <div className={className}>
            <video controls autoPlay={videoJsOptions.autoplay} muted={videoJsOptions.muted} poster={videoJsOptions.poster} className="media-mp4">
              <source src={srcSplit[0]} type="video/mp4" />
            </video>
          </div>
        );
      }
    }
    if (this.type === 'vimeo') {
      let params = queryString.stringify(playerVars);
      let src = `//player.vimeo.com/video/${this.id}/?${params}`;
      return (
        <div className={className}>
          <EmbedWrapper>
            <iframe src={src} frameBorder="0" allowFullScreen />
          </EmbedWrapper>
        </div>
      );
    }
    if (this.type === 'youtube') {
      let src = `//www.youtube.com/embed/${this.id}`;
      return (
        <div className={className}>
          <EmbedWrapper>
            <iframe src={src} allowFullScreen allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" frameBorder="0"></iframe>
          </EmbedWrapper>
        </div>
      );
    }
    return(
      <div className={className}>
        <EmbedWrapper>
          <iframe allowFullScreen frameBorder="0" src={src} />
        </EmbedWrapper>
      </div>
    );
  }
}

VideoContent.propTypes = {
  className: PropTypes.string,
  onComplete: PropTypes.func,
  onProgress: PropTypes.func,
  video: videoShape.isRequired,
};

VideoContent.defaultProps = {
  onComplete: () => {},
  onProgress: () => {},
  video: { src: null },
};

export default VideoContent;
