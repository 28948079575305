import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M22,22 L13.7,13.7 L22,22 Z M15.5,9 C15.6,12.5 12.9,15.4 9.4,15.5 C9.3,15.5 9.1,15.5 9,15.5 C5.4,15.5 2.5,12.6 2.5,9 C2.5,5.4 5.4,2.5 9,2.5 C12.6,2.5 15.5,5.4 15.5,9 C15.5,9 15.5,9 15.5,9 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <path d="M42,42 L29,29 L42,42 Z M32.5,20 C32.5,27.6 26.8,32.5 20,32.5 C13.1,32.5 7.5,26.9 7.5,20 C7.5,13.1 13.1,7.5 20,7.5 C26.9,7.5 32.5,13.1 32.5,20 Z" stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd"/>
    </svg>
  );
};
