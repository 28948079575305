import React from 'react';
import Helmet from 'react-helmet';
import Loading from '../../components/Loading';
import TextField from '../../audi-ui-components/TextField';
import Button from '../../audi-ui-components/Button';
import { CMSSectionList } from '../../components/cms';
import DonateForm from './DonateForm';
import { gtmPageview, gtmPush } from '../../functions/gtm';

import {
  FEATURE_TOGGLE
} from '../../constants';

import { connect } from 'react-redux';
import { requestPageData } from '../../redux/content/pages';
const mapStateToProps = state => {
  return {
    hasLoaded: state.pages.donate ? state.pages.donate.hasLoaded : false,
    pageData: state.pages.donate
  }
}
const mapDispatchToProps = dispatch => {
  return {
    requestPageData: (slug) => { dispatch(requestPageData(slug)); }
  }
}

class Donate extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      useTxtAmt: false,
      submitted: false,
      showForm: false,
    };
    this.formTopRef = React.createRef();
  }
  
  componentDidMount() {
    gtmPageview(this.props.location.pathname);
    if (!this.props.hasLoaded) {
      this.props.requestPageData('donate');
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  
  onClickDonate = () => {
    this.setState({showForm: true});
    if (this.state.amount) {
      gtmPush("audiFoundation", "amountDonateNowClick", `donation_amount = ${this.state.amount}`, {totalAmount: this.state.amount});
      let rect = this.formTopRef.current ? this.formTopRef.current.getBoundingClientRect() : {};
      let top = rect.top + window.pageYOffset;
      let ww = window.innerWidth;
      let header = 119;
      if (ww < 768) { header = 45; }
      setTimeout(() => { window.scrollTo({top: top - header, behaviour: 'smooth'}); }, 500);
    }
  }
  
  onChangeAmtOpt = (e) => {
    // console.log("onChangeAmtOpt", e.target.value, e.target.checked);
    if (e.target.checked) {
      this.setState({amount: e.target.value, useTxtAmt: false});
    }
  }
  onChangeAmtTxt = (v) => {
    // console.log("onChangeAmtTxt", v);
    if (!v || v === "") {
      this.setState({amount: 0, useTxtAmt: false});
    } else {
      let _v = v.replace(/\D/g,'');
      this.setState({amount: _v, useTxtAmt: true});
    }
  }
  
  render() {
    const { hasLoaded, pageData } = this.props;
    const { amount, useTxtAmt, showForm, submitted } = this.state;
    return (
      <div className="page donate">
        <Helmet>
          <title>Donate | Audi Foundation</title>
          <meta name="description" content="Audi Foundation Donate" />
          <meta property="og:site_name" content="Audi Foundation Donate" />
        </Helmet>
        
        {FEATURE_TOGGLE.DONATE.maintenance && <div className="content-wrapper">
          <p className="mb-3">Audi Foundation is currently undergoing maintenance and donations have been temporarily disabled. Please check back another time.</p>
          <p className="mb-5">Thankyou for your support.</p>
          <p className="mb-5">&nbsp;</p>
        </div>}
        
        {!FEATURE_TOGGLE.DONATE.maintenance && <>
          
          {!hasLoaded && <Loading />}
          
          {(hasLoaded && !submitted) && <>
            <section className="donation-hero" style={{ backgroundImage: `url("${pageData.heroImage}")` }}>
              <div className="donation-hero__inner content-wrapper" style={{ backgroundImage: `url("https://cdn01.audistaging.com.au/cms/audifoundation/media/1113/donate_hero_mobile.jpg")` }}>
                {pageData && pageData.sections && <CMSSectionList sections={[pageData.sections[0]]} />}
                <div className="donation-card">
                  <p className="aui-headline-4 mb-2">How much would you like to donate?</p>
                  <div className="donation-options" role="group">
                    {pageData.options && pageData.options.map(value => (
                        <label key={value} className={(value === amount && !useTxtAmt) ? "active" : ""}>
                            <input name="amount"
                              type="radio"
                              value={value}
                              checked={value === amount && !useTxtAmt}
                              onChange={this.onChangeAmtOpt}
                            />
                            <span>${value}</span>
                        </label>
                    ))}
                  </div>
                  <TextField name="amount"
                    label="Or enter your own amount"
                    floatingLabel={false}
                    icon={<span className="audiicon">$</span>}
                    invalidMessage="Please enter or select an amount"
                    isInvalid={this.state.showForm && (!amount || amount === "" || amount < 1)}
                    value={useTxtAmt ? amount : ""}
                    onChange={this.onChangeAmtTxt}
                  />
                  <Button variant="primary" className="red" onClick={this.onClickDonate}>Donate now</Button>
                  <p className="aui-small mt-1">All donations above $2 are tax deductible</p>
                </div>
              </div>
            </section>
            <div ref={this.formTopRef} />
            
            {(showForm && amount > 0) && <div className="page-inner">
              <div className="content-wrapper">
                <DonateForm
                  amount={amount}
                  onSubmit={() => {
                    this.setState({submitted: true});
                    setTimeout(() => {
                      window.scrollTo(0, 0);
                    }, 100);
                  }}
                />
                {pageData && pageData.disclaimer && <div className="aui-small" dangerouslySetInnerHTML={{__html: pageData.disclaimer}} />}
              </div>
            </div>}
          </>}
          
          {(hasLoaded && submitted) && <div className="page-inner">
            <div className="content-wrapper">
              {pageData && pageData.sections && <CMSSectionList sections={[pageData.sections[1]]} />}
              <p className="my-3 text-center">
                <Button variant="secondary" to="/">Back to Homepage</Button>
              </p>
            </div>
          </div>}
          
        </>}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Donate);
