import React from 'react';
import Button from '../../audi-ui-components/Button';
import Loading from '../../components/Loading';
import { Formik, Form } from 'formik';
import FormField from '../../components/FormField';
import API from '../../functions/api';
import { formatMobile } from '../../functions/stringHelpers';
import { loadStripe, formatCreditCard, formatCCExpire } from '../../functions/payment';
import { validate, defaultSchema } from '../../functions/validation';
import { gtmPush } from '../../functions/gtm';

class DonateForm extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      error: false
    };
  }
  
  componentDidMount() {
    loadStripe(process.env.RAZZLE_STRIPE_KEY_DONATE);
  }
  
  validateForm = (values) => {
    let schema = {
      firstName: defaultSchema.firstName,
      lastName: defaultSchema.lastName,
      email: defaultSchema.email,
      phoneNumber: defaultSchema.phoneNumber,
      cardNumber: {
        stripeCardNumber: true,
        presence: {message: "This field is required", allowEmpty: false}
      },
      cardExpiry: {
        stripeCardExpiry: true,
        presence: {message: "This field is required", allowEmpty: false}
      },
      cardCVC: {
        stripeCVC: true,
        presence: {message: "This field is required", allowEmpty: false}
      }
    };
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }
  
  submitForm = (values, formProps) => {
    const { amount } = this.props;
    let Stripe = null;
    if (typeof window !== 'undefined' && window.Stripe) {
      Stripe = window.Stripe;
    } else {
      this.setState({error: "Payment gateway not loaded"});
      formProps.setSubmitting(false);
      return;
    }
    const cardDetails = {
      number: values.cardNumber.replace(/ /g, ''),
      cvc: values.cardCVC,
      exp_month: values.cardExpiry.substr(0,2),
      exp_year: values.cardExpiry.substr(3),
    }
    Stripe.card.createToken(cardDetails, (status, response) => {
      if (response.error) {
        this.setState({error: response.error.message});
        formProps.setSubmitting(false);
        return;
      } else {
        let donationObj = {
            chargeToken: response.id,
            totalAmount: amount,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            optout: true,
            optin: false
        };
        API.post(
          "/donate",
          donationObj
        ).then((response) => {
            // console.log(response);
            gtmPush("audiFoundation", "donateSubmission", "donation_submitted", {totalAmount: amount, firstName: values.firstName, lastName: values.lastName, email: values.email, phoneNumber: values.phoneNumber});
            this.props.onSubmit(response);
        }).catch((error) => {
            // console.error(error);
            let msg = "An error has occured.";
            if (error.response && error.response.data) {
              if (error.response.data.message) {
                msg = error.response.data.message;
              }
              if (error.response.data.modelState) {
                formProps.setErrors(error.response.data.modelState);
              }
            }
            this.setState({error: msg});
            formProps.setSubmitting(false);
        });
      }
    });
  }
  
  render() {
    return (
      <div className="form-section mt-0">
        <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{}}>
          {(formProps) => (
            <Form>
              
              <p className="aui-headline-4 mb-2">Enter your contact details</p>
              <div className="row">
                <FormField name="firstName" label="First name" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                <FormField name="lastName" label="Last name" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
              </div>
              <div className="row">
                <FormField name="email" label="Email address" trim wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                <FormField name="phoneNumber" label="Mobile number" formatValue={formatMobile} wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
              </div>
              
              <p className="aui-headline-4 mb-2">Enter your payment details</p>
              <FormField name="cardNumber" label="Card number" formatValue={formatCreditCard} formProps={formProps} />
              <div className="row">
                <FormField name="cardExpiry" label="Expiry (MM/YY)" formatValue={formatCCExpire} wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                <FormField name="cardCVC" label="CVC" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
              </div>
              
              {this.state.error && <p className="aui-color-text-red py-2"><b>{this.state.error}</b></p>}
              <Button type="submit" variant="primary" className="red">Confirm donation of ${this.props.amount}</Button>
              {formProps.isSubmitting && <Loading type="overlay" />}
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default DonateForm;
