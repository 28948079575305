import API from '../../functions/api';

const DEALERS_LOADED = 'dealers/loaded';
const DEALERS_REQUESTED = 'dealers/requested';

export function requestDealers(cb) {
    return (dispatch) => {
        dispatch({
            type: DEALERS_REQUESTED,
        });

        return API.get('/dealers')
        .then((res) => {
            dispatch({
                type: DEALERS_LOADED,
                data: res.data,
            });
            if (typeof cb === "function") { cb(res.data); }
        })
        .catch((error) => {
            console.log(`Couldn't get dealers:`, error);
        });
    };
}

const defaultContentState = {
    hasLoaded: false,
    isFetching: false,
    data: null,
};

export default function dealers(state = defaultContentState, action) {
    switch (action.type) {
    case DEALERS_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case DEALERS_LOADED:
        return Object.assign({}, state, {
            hasLoaded: true,
            isFetching: false,
            data: action.data,
        });
    default:
        return state;
    }
}
