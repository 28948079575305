import React from 'react';
import classnames from 'classnames';

// CMS Sections
// import CaptionedCarouselSection from './CaptionedCarouselSection';
// import CarouselSection from './CarouselSection';
// import FeaturesSection from './FeaturesSection';
import HtmlSection from './HtmlSection';
import ImageSection from './ImageSection';
// import LevelsSection from './LevelsSection';
import OverviewSection from './OverviewSection';
// import ProgramSection from './ProgramSection';
import QuotesSection from './QuotesSection';
import ShortFormSection from './ShortFormSection';
// import SkillsSection from './SkillsSection';
// import TabsSection from './TabsSection';
// import VideoCarouselSection from './VideoCarouselSection';

// CMS Constants
import {
    // SECTION_CAPTIONED_CAROUSEL,
    // SECTION_CAROUSEL,
    // SECTION_FEATURES,
    SECTION_HTML,
    SECTION_IMAGE,
    // SECTION_LEVELS,
    SECTION_OVERVIEW,
    // SECTION_PROGRAM,
    SECTION_QUOTES,
    SECTION_SHORTFORM,
    // SECTION_SKILLS,
    // SECTION_TABS,
    // SECTION_VIDEO_CAROUSEL,
} from './constants';

import {
    sectionMissmatchWarning,
} from './helpers';

class CMSSectionList extends React.Component {

    renderSection(section, idx, showWarnings) {
        const { type, layout } = section;

        switch (type) {

        case SECTION_HTML:
            return <HtmlSection key={idx} {...section} />;

        case SECTION_IMAGE:
            return <ImageSection key={idx} {...section} />;

        case SECTION_OVERVIEW:
            return <OverviewSection key={idx} {...section} />;

        case SECTION_SHORTFORM:
            return <ShortFormSection key={idx} {...section} />;
        
        case SECTION_QUOTES:
            return <QuotesSection key={idx} {...section} />;

        default:
            return sectionMissmatchWarning(type, 'CMSSectionList', showWarnings);
        }
    }

    render() {
        const { className, sections, showWarnings } = this.props;
        
        if (!sections) return null;

        return (
            <div className={classnames('cms-section-list', className)}>
                {sections.map((section, idx) => this.renderSection(section, idx, showWarnings))}
            </div>
        );
    }
}

export default CMSSectionList;
