
export function loadStripe(key) {
    if (typeof window !== 'undefined') {
        if (window.Stripe) {
            window.Stripe.setPublishableKey(key);
        } else {
            var script = document.createElement("script");
            script.type = "text/javascript";
            script.src = "https://js.stripe.com/v2/";
            script.onload = function () {
                window.Stripe.setPublishableKey(key);
            };
            document.getElementsByTagName("head")[0].appendChild(script);
        }
    }
}

export function formatCreditCard(value) {
  if (value === '') { return ''; }
  let rawdata = value.replace(/\D/g,'');
  if (rawdata.length < 5) {
    return rawdata;
  }
  let formatted = '';
  let firstTwo = rawdata.substring(0,2);
  if (firstTwo === '34' || firstTwo === '37') {
    // amex
    rawdata = rawdata.substring(0,15);
    if (rawdata.length < 11) {
      formatted = rawdata.replace(/\b(\d{4})(\d{1,6})\b/, '$1 $2');
    } else {
      formatted = rawdata.replace(/\b(\d{4})(\d{6})(\d{1,5})\b/, '$1 $2 $3');
    }
  } else {
    // normal cc
    rawdata = rawdata.substring(0,16);
    let pieces = rawdata.match(/\d{1,4}/g);
    formatted = pieces ? pieces.join(' ') : '';
  }
  return formatted;
};

export function formatCCExpire(string) {
    return string.replace(
        /[^0-9]/g, '' // To allow only numbers
    ).replace(
        /^([2-9])$/g, '0$1' // To handle 3 > 03
    ).replace(
        /^(1{1})([3-9]{1})$/g, '0$1/$2' // 13 > 01/3
    ).replace(
        /^0{1,}/g, '0' // To handle 00 > 0
    ).replace(
        /^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1/$2' // To handle 113 > 11/3
    );
}
