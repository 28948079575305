// Application constants
export const APPLICATION_CONTAINER_ID = 'AppContainer';

// Social
export const SOCIAL_FACEBOOK = 'https://www.facebook.com/AudiAustralia';
export const SOCIAL_INSTAGRAM = false;
export const SOCIAL_LINKEDIN = false;
export const SOCIAL_TWITTER = false;

// Page Limits
export const ARTICLES_PAGE_SIZE = 12;
export const SEARCH_PAGE_SIZE = 12;

// Meta
export const META_DESCRIPTION = 'Audi Foundation';
export const META_KEYWORDS = 'Audi Foundation';
export const META_SITE_NAME = 'Audi Foundation';
export const META_TITLE = 'Audi Foundation';

// Paths
export const PATH_HOME = '/';
export const PATH_ABOUT = '/about';
export const PATH_DONATE = '/donate';
export const PATH_FAQ = '/faq'; // '/faq/(:slug)'
export const PATH_PARTNERS = '/partners'; // '/partners/:slug'
export const PATH_PRIZE_DRAWS = '/prizedraw';
export const PATH_RAFFLE = '/raffle';
export const PATH_STORIES = '/impact';
export const PATH_STORIES_TAG = '/impact/category'; // '/impact/category/:tag'
export const PATH_STORIES_SINGLE = '/impact/story'; // '/impact/story/:slug'
export const PATH_SUBSCRIBE = '/subscribe';
export const PATH_SUPPORTERS = '/supporters'; // '/supporters/(:state)'
export const PATH_RECEIPTS = '/receipts';

// Links
export const MEDIA_URL = 'https://cdn01.audistaging.com.au/cms/audifoundation/media';
export const LINK_LEGAL = `https://cdn01.audi.com.au/cms/audifoundation/media/1163/audi-foundation-legal-statement-final-04042017.pdf`;
export const LINK_PRIVACY = `https://cdn01.audi.com.au/cms/audifoundation/media/1162/audi-foundation-privacy-policy-final-04042017-2.pdf`;
export const LINK_FUNDRAISING_LICENSE = `https://cdn01.audi.com.au/cms/audifoundation/media/1245/fundraising-licences.pdf`;

// Styles
export const HEADER_HEIGHT_SMALL = 45;
export const HEADER_HEIGHT_MEDIUM = 119;

// Form field constants
export const FIELD_CHECKBOX = 'FIELD_CHECKBOX';
export const FIELD_RADIOS = 'FIELD_RADIO';
export const FIELD_SELECT = 'FIELD_SELECT';
export const FIELD_DEALER_SELECT = 'FIELD_DEALER_SELECT';
export const FIELD_DROPDOWN = 'FIELD_DROPDOWN';
export const FIELD_TEXT = 'FIELD_TEXT';
export const FIELD_TEXTAREA = 'FIELD_TEXTAREA';
export const FIELD_SWITCH = 'FIELD_SWITCH';
export const FIELD_DATEPICKER = 'FIELD_DATEPICKER';

// Google API
export const GOOGLE_MAPS_API_KEY = 'AIzaSyCZFaQ-hxLCbSY-Qh0i0be5qTyMq5se0bk';
export const GOOGLE_RECAPTCHA_KEY = "6LccOa8UAAAAAFXmJeyRQaLQdTinjCZ9svHrgyfE";

// Prize draw status
export const PRIZE_DRAW_ACTIVE = 'Active';
export const PRIZE_DRAW_CLOSED = 'Closed';
export const PRIZE_DRAW_BROKEN = 'Error';
export const PRIZE_DRAW_DRAWN = 'Drawn';
export const PRIZE_DRAW_SOLD_OUT = 'SoldOut';
export const PRIZE_DRAW_NO_RAFFLE = 'NoRaffle';

export const FEATURE_TOGGLE = {
  MAINTENANCE: false,
  RAFFLE: {
    route: true,
    nav: true,
  },
  DONATE: {
      maintenance: false,
      nav: true
  },
  RECEIPTS: true,
  SUBSCRIBE: {
      maintenance: false,
      nav: true
  }
};

// Data
export const AUS_STATES = {
    NSW: 'New South Wales',
    ACT: 'Australian Capital Territory',
    VIC: 'Victoria',
    QLD: 'Queensland',
    SA: 'South Australia',
    TAS: 'Tasmania',
    NT: 'Northern Territory',
    WA: 'Western Australia'
};

export const ERROR_MSG_GENERIC = "There was a problem submitting the form. Please try again.";
export const ERROR_MSG_FIELDS = "There was a problem with the information you submitted. Please check the fields above for errors.";

export const DONATION_OPTIONS = [ 50, 100, 250, 500 ];
