import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <path d="M17.1,6 L6.9,6 C6.4,6 6,6.4 6,6.9 L6,17.2 C6,17.6 6.4,18 6.9,18 L17.1,18 C17.6,18 18,17.6 18,17.1 L18,6.9 C18,6.4 17.6,6 17.1,6 Z M9.6,16.2 L7.8,16.2 L7.8,10.5 L9.6,10.5 L9.6,16.2 Z M8.7,9.7 C8.1,9.7 7.7,9.2 7.7,8.7 C7.7,8.1 8.2,7.7 8.7,7.7 C9.2,7.7 9.7,8.2 9.7,8.7 C9.7,9.3 9.2,9.7 8.7,9.7 Z M16.2,16.2 L14.4,16.2 L14.4,13.4 C14.4,12.7 14.4,11.9 13.5,11.9 C12.6,11.9 12.4,12.6 12.4,13.4 L12.4,16.2 L10.6,16.2 L10.6,10.5 L12.3,10.5 L12.3,11.3 C12.5,10.9 13.1,10.4 14,10.4 C15.8,10.4 16.1,11.6 16.1,13.1 L16.1,16.2 L16.2,16.2 Z" fill="currentColor" fillRule="nonzero" stroke="none" strokeWidth="1"/>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <path d="M34.2,12 L13.8,12 C12.8,12 12,12.8 12,13.7 L12,34.2 C12,35.2 12.8,35.9 13.8,35.9 L34.3,35.9 C35.3,35.9 36.1,35.1 36.1,34.2 L36.1,13.7 C36,12.8 35.2,12 34.2,12 Z M19.1,32.5 L15.5,32.5 L15.5,21 L19.1,21 L19.1,32.5 Z M17.3,19.4 C16.2,19.4 15.2,18.5 15.2,17.3 C15.2,16.2 16.1,15.2 17.3,15.2 C18.5,15.2 19.4,16.1 19.4,17.3 C19.4,18.5 18.5,19.4 17.3,19.4 Z M32.5,32.5 L28.9,32.5 L28.9,26.9 C28.9,25.6 28.9,23.9 27,23.9 C25.1,23.9 24.9,25.3 24.9,26.8 L24.9,32.5 L21.3,32.5 L21.3,21 L24.7,21 L24.7,22.6 C25.2,21.7 26.3,20.7 28.1,20.7 C31.7,20.7 32.4,23.1 32.4,26.2 L32.5,32.5 Z" fill="currentColor" fillRule="nonzero" stroke="none" strokeWidth="1"/>
    </svg>
  );
};
