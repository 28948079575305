import API from '../../functions/api';

const PARTNERS_LIST_LOADED = 'partners/list/loaded';
const PARTNERS_LIST_REQUESTED = 'partners/list/requested';

export function requestPartners() {
    return (dispatch) => {
        dispatch({
            type: PARTNERS_LIST_REQUESTED,
        });

        return API.get('/partners')
        .then((res) => {
            dispatch({
                type: PARTNERS_LIST_LOADED,
                data: res.data,
            });
        })
        .catch((error) => {
            console.log(`Couldn't get partners:`, error);
        });
    };
}

const defaultContentState = {
    hasLoaded: false,
    isFetching: false,
    data: null,
};

export default function partnersList(state = defaultContentState, action) {
    switch (action.type) {
    case PARTNERS_LIST_REQUESTED:
        return Object.assign({}, state, {
            isFetching: true,
        });
    case PARTNERS_LIST_LOADED:
        return Object.assign({}, state, {
            hasLoaded: true,
            isFetching: false,
            data: action.data,
        });
    default:
        return state;
    }
}
