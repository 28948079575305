import React from 'react';
import Helmet from 'react-helmet';
import { CMSSectionList } from '../components/cms';
import Button from '../audi-ui-components/Button';
import Hero from '../components/Hero';
import Loading from '../components/Loading';
import { Formik, Form } from 'formik';
import FormField from '../components/FormField';
import ReCAPTCHA from "react-google-recaptcha";
import API from '../functions/api';
import { validate, defaultSchema } from '../functions/validation';
import { gtmPageview, gtmPush } from '../functions/gtm';
import {
  FEATURE_TOGGLE
} from '../constants';

import { connect } from 'react-redux';
import { requestPageData } from '../redux/content/pages';
const mapStateToProps = state => {
  return {
    hasLoaded: state.pages.subscribe ? state.pages.subscribe.hasLoaded : false,
    pageData: state.pages.subscribe
  }
}
const mapDispatchToProps = dispatch => {
  return {
    requestPageData: (slug) => { dispatch(requestPageData(slug)); }
  }
}

class Subscribe extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      token: false,
      error: false
    };
  }
  
  componentDidMount() {
    gtmPageview(this.props.location.pathname);
    if (!this.props.hasLoaded) {
      this.props.requestPageData('subscribe');
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  
  validateForm = (values) => {
    let schema = {
      firstName: defaultSchema.firstName,
      lastName: defaultSchema.lastName,
      email: defaultSchema.email
    };
    return validate(values, schema, {format: "firstError", fullMessages: false});
  }
  
  submitForm = (values, formProps) => {
    let payload = Object.assign({}, values, {token: this.state.token});
    API.post('/subscribe', payload)
    .then((res) => {
      // success
      this.setState({submitted: true});
      formProps.setSubmitting(false);
      gtmPush("audiFoundation", "subscribeSuccess", "subscription_completed", values);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }).catch((error) => {
      // console.log(error.response);
      let msg = "An error has occured.";
      if (error.response && error.response.data) {
        if (error.response.data.message) {
          msg = error.response.data.message;
        }
        if (error.response.data.modelState) {
          formProps.setErrors(error.response.data.modelState);
        }
      }
      this.setState({error: msg});
      formProps.setSubmitting(false);
    });
  }

  render() {
    const { pageData, hasLoaded } = this.props;
    
    var _sections = (pageData && pageData.sections) ? [...pageData.sections] : [
      {
        components: [
          {
            content: {html: "<h1>Subscribe</h1>"},
            id: "subscribe1",
            type: "COMPONENT_HTML"
          }
        ],
        id: "subscribe-default",
        layout: {spacing: "LARGE", type: "", width: "FULL"},
        type: "SECTION_OVERVIEW"
      }
    ];
    var lastSection = false;
    var len = _sections.length;
    if (len > 1) {
      lastSection = _sections[len - 1];
      _sections = _sections.slice(0, len - 1);
    }

    return (
      <div className="page subscribe">
        <Helmet>
          <title>Subscribe | Audi Foundation</title>
          <meta name="description" content={(pageData && pageData.metaDescription) ? pageData.metaDescription : "Audi Foundation Subscribe"} />
          <meta property="og:site_name" content={(pageData && pageData.metaTitle) ? pageData.metaTitle : "Audi Foundation Subscribe"} />
        </Helmet>
        
        {pageData && pageData.heroImage && <Hero heroImage={pageData.heroImage} />}
        
        <div className="page-inner">
          
          {FEATURE_TOGGLE.SUBSCRIBE.maintenance && <div className="content-wrapper">
            <p className="mb-3">Audi Foundation is currently undergoing maintenance and subscriptions have been temporarily disabled. Please check back another time.</p>
            <p className="mb-5">Thankyou for your support.</p>
            <p className="mb-5">&nbsp;</p>
          </div>}

          {!FEATURE_TOGGLE.SUBSCRIBE.maintenance && <div className="content-wrapper">
            {!this.state.submitted && <CMSSectionList sections={_sections} />}
            
            {!this.state.submitted && <div className="form-section">
              <Formik validate={this.validateForm} onSubmit={this.submitForm} initialValues={{}}>
                {(formProps) => (
                  <Form>
                    <div className="row">
                      <FormField name="firstName" label="First name" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                      <FormField name="lastName" label="Last name" wrapperClassName="col-12 col-small-6 mb-3" formProps={formProps} />
                    </div>
                    <FormField name="email" label="Email address" formProps={formProps} />
                    <div className="mb-3">
                      <ReCAPTCHA sitekey={process.env.RAZZLE_GOOGLE_RECAPTCHA_KEY} onChange={(v) => { this.setState({token: v}); }} />
                    </div>
                    {this.state.error && <p className="aui-color-text-red py-2">{this.state.error}</p>}
                    <Button type="submit" variant="primary" className="red" disabled={!this.state.token}>Submit</Button>
                    {formProps.isSubmitting && <Loading type="overlay" />}
                  </Form>
                )}
              </Formik>
            </div>}
            
            {this.state.submitted && <div className="page-intro">
              <p className="aui-headline-3 mb-3">Thanks for subscribing.</p>
              <p className="mb-5">We'll keep you updated.</p>
            </div>}
            
            {lastSection && <CMSSectionList sections={[lastSection]} />}
          </div>}
        
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
