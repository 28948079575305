import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const TextField = React.forwardRef((props, ref) => {
  
  const [isFocused, setIsFocused] = useState(false);

  const handleChange = (e) => {
    if (typeof props.onChange === 'function') {
      props.onChange(e.target.value, e);
    }
  }

  const handleFocus = (e) => {
    setIsFocused(true);
    if (typeof props.onFocus === 'function') {
      props.onFocus(e);
    }
  }

  const handleBlur = (e) => {
    setIsFocused(false);
    if (typeof props.onBlur === 'function') {
      props.onBlur(e);
    }
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 13 && typeof props.onEnter === 'function') {
      props.onEnter();
    }
    if (typeof props.onKeyDown === 'function') {
      props.onKeyDown(e);
    }
  }

  const renderMessage = () => {
    const {isValid, isInvalid, validMessage, invalidMessage, description} = props;
    if (isValid && validMessage) {
      return (<span className="aui-textfield__valid">{validMessage}</span>);
    }
    if (isInvalid && invalidMessage) {
      return (<span className="aui-textfield__error">{invalidMessage}</span>);
    }
    if (description) {
      return (<span className="aui-textfield__description">{description}</span>);
    }
    return false;
  }

  var autoFillProp = {};
  if (props.autoComplete) {
    autoFillProp = {autoComplete: props.autoComplete};
  }
  
  return(
    <div style={props.style} className={classnames(
      "aui-textfield",
      `${props.className}`,
      {
        "aui-textfield--floating-label": props.floatingLabel,
        "is-focused": isFocused,
        "is-dirty": props.value || props.value === 0,
        "is-valid": props.isValid,
        "is-invalid": props.isInvalid,
        "aui-textfield--icon": props.icon,
        [`aui-theme-${props.theme}`]: props.theme
      }
    )}>
      <div className="aui-textfield__field">
        {props.icon && props.icon}
        <input name={props.name}
          id={props.name}
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
          maxLength={props.maxLength}
          disabled={props.disabled}
          className="aui-textfield__input"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          {...autoFillProp}
          ref={ref}
        />
        <label className="aui-textfield__label" htmlFor={props.name}>{props.label}</label>
        <span className="aui-textfield__focus-line"></span>
      </div>
      {renderMessage()}
    </div>
  );
});

TextField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  icon: PropTypes.node,
  floatingLabel: PropTypes.bool,
  type: PropTypes.string,
  maxLength: PropTypes.number,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  invalidMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  validMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isValid: PropTypes.bool,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light']) // see styles/components/_textfield-themes.scss
};

TextField.defaultProps = {
  type: 'text',
  floatingLabel: true,
  onChange: () => {},
  style: {},
  className: ''
}

export default TextField;
