import React from 'react';
import classnames from 'classnames';
import queryString from 'query-string';
import Facebook from './icons/Facebook';
import Instagram from './icons/Instagram';
import Linkedin from './icons/Linkedin';
import Twitter from '../audi-ui-components/icons/Twitter';
import {
  SOCIAL_FACEBOOK,
  SOCIAL_INSTAGRAM,
  SOCIAL_LINKEDIN,
  SOCIAL_TWITTER,
} from '../constants';
import { joinTagsObject } from '../functions/stringHelpers';
import { gtmPush } from '../functions/gtm';

const stripHtml = html => html.replace(/<(?:.|\n)*?>/gm, '');

const getShareUrl = (channel, _data, url) => {
  let data = typeof _data === 'object' ? _data : {};
  if (data.description) {
    data.description = stripHtml(data.description);
  }
  if (channel === 'facebook') {
    // https://stackoverflow.com/questions/20956229/has-facebook-sharer-php-changed-to-no-longer-accept-detailed-parameters
    let obj = {
      u: url
    };
    if (data.title) { obj.title = data.title; }
    if (data.description) { obj.description = data.description; }
    if (data.text) { obj.quote = data.text; }
    let q = queryString.stringify(obj);
    return `https://facebook.com/sharer.php?${q}`;
  }

  if (channel === 'linkedin') {
    let obj = {
      url: url
    };
    if (data.title) { obj.title = data.title; }
    if (data.description) { obj.summary = data.description; }
    let q = queryString.stringify(obj);
    return `https://linkedin.com/shareArticle?${q}`;
  }
  
  if (channel === 'twitter') {
    // https://developer.twitter.com/en/docs/twitter-for-websites/tweet-button/overview
    let obj = {
      url: url
    };
    if (data.twitterText) { obj.text = data.twitterText; }
    let q = queryString.stringify(obj, {arrayFormat: 'comma'})
    // return `https://twitter.com/intent/tweet?${q}`;
    return `https://twitter.com/share?${q}`;
  }
  return "";
}

const trackSocial = (channel, share) => {
  let action = share ? "shareClicked" : "socialClicked";
  let data = {channel: channel, href: window.location.href};
  let label = `social_type = ${channel}`;
  if (share && share.story) {
    data.category = story.category;
    data.tags = joinTagsObject(story.tags);
    data.partner = story.partner;
    label = `social_share_type = ${channel}`;
  }
  gtmPush("audiFoundation", action, label, data);
}

class Social extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      href: (props.share && props.share.url) ? props.share.url : typeof window !== "undefined" ? window.location.href : ""
    };
  }
  
  componentDidMount() {
    if (!this.state.href || this.state.href === "") {
      this.setState({href: window.location.href});
    }
  }
  
  render() {
    const { className, share, title } = this.props;
    const { href } = this.state;
    if (!href || href === "") {
      return null;
    }
    return (
      <div className={classnames('social', className, {'share': share})}>
        {share && <p className="social__share">{title}</p>}
        <div className="icons">
          {(SOCIAL_FACEBOOK || share) && 
            <a aria-label="Facebook" href={share ? getShareUrl('facebook', share, href) : SOCIAL_FACEBOOK} onClick={() => {trackSocial('facebook', share);}} target="_blank" rel="nofollow">
              <Facebook small />
            </a>
          }
          {SOCIAL_INSTAGRAM && 
            <a aria-label="Instagram" href={share ? getShareUrl('instagram', share, href) : SOCIAL_INSTAGRAM} onClick={() => {trackSocial('instagram', share);}} target="_blank" rel="nofollow">
              <Instagram small />
            </a>
          }
          {((!share && SOCIAL_LINKEDIN) || (share && share.linkedin)) && 
            <a aria-label="Linkedin" href={share ? getShareUrl('linkedin', share, href) : SOCIAL_LINKEDIN} onClick={() => {trackSocial('linkedin', share);}} target="_blank" rel="nofollow">
              <Linkedin small />
            </a>
          }
          {((!share && SOCIAL_TWITTER) || (share && share.twitter)) && 
            <a aria-label="Twitter" href={share ? getShareUrl('twitter', share, href) : SOCIAL_TWITTER} onClick={() => {trackSocial('twitter', share);}} target="_blank" rel="nofollow">
              <Twitter small />
            </a>
          }
        </div>
      </div>
    );
  }
}

Social.defaultProps = {
  className: "my-3 text-center",
  title: "Share this page"
};

export default Social;
