import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const { string, shape, number, oneOfType } = PropTypes;
export const imageShape = shape({
    src: string.isRequired,
    width: oneOfType([string, number]),
    height: oneOfType([string, number]),
    alt: string,
});

class ImageContent extends React.Component {

    static propTypes = {
        className: string,
        image: imageShape,
    };

    render() {
        const { className, image } = this.props;
        const classes = classnames(className, 'content-image');

        return (
            <div className={classes}>
                <img src={image.src} alt={image.alt} />
            </div>
        );
    }
}

export default ImageContent;
