import React from 'react';
import classnames from 'classnames';
import { unOrphan } from './helpers'

import PropTypes from 'prop-types';
const { string, shape } = PropTypes;
export const quoteShape = shape({
  text: string.isRequired,
  source: shape({
    name: string.isRequired,
    link: string,
    align: string,
  }),
});

const QuoteContent = ({className, quote}) => {
  return (
    <div className={classnames("content-quote", className)}>
      <blockquote>
        <p dangerouslySetInnerHTML={{ __html: '"' + unOrphan(quote.text) + '"'}} ></p>
        <cite className={`text-${quote.source.align || 'center'}`}>
          {(quote.source.link && quote.source.name) && <a target="_blank" href={quote.source.link}>
            {quote.source.name}
          </a>}
          {(quote.source.name && !quote.source.link) && <>{quote.source.name}</>}
        </cite>
      </blockquote>
    </div>
  );
}

QuoteContent.propTypes = {
  className: string,
  quote: quoteShape.isRequired,
};

export default QuoteContent;
