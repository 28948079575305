// Store
import { thunk } from './middleware';
import { createStore, applyMiddleware, combineReducers, compose } from 'redux';

// Reducers
import articles from './content/articles';
import boardMembers from './content/boardMembers';
import dealers from './content/dealers';
import articlesList from './content/articlesList';
import articleCategories from './content/articleCategories';
import articleTags from './content/articleTags';
import pages from './content/pages';
import partnersList from './content/partnersList';
import partnerCategoriesList from './content/partnerCategoriesList';
import partners from './content/partners';
import prizedraw from './content/prizedraw';

const combinedReducers = combineReducers({
    articles,
    articleCategories,
    articleTags,
    articlesList,
    boardMembers,
    dealers,
    pages,
    partners,
    partnersList,
    partnerCategoriesList,
    prizedraw
});
let devTools = typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
const composeEnhancers = devTools ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose;

export const createAppStore = (initialState) => createStore(combinedReducers, initialState, composeEnhancers(applyMiddleware(thunk)));

// https://github.com/zalmoxisus/redux-devtools-extension
