import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

class Hero extends React.Component {
    render() {
        const {
            children,
            className,
            contentAlignment,
            contentPosition,
            fullScreen,
            heroImage,
            hasShadow,
        } = this.props;
        
        if (!heroImage) {
            return null;
        }

        const heroStyles = {
            backgroundImage: `url(${heroImage})`,
        };

        const heroClasses = classnames('hero', className, {
            'hero--fullscreen': fullScreen,
            'hero--shadow': hasShadow,
        });

        const contentClasses = classnames('hero__content', {
            [`hero__content--position-${contentPosition}`]: contentPosition,
            [`hero__content--align-${contentAlignment}`]: contentAlignment,
        });

        return (
            <div className={heroClasses} style={heroStyles}>
                {children &&
                    <div className={contentClasses}>
                        {children}
                    </div>
                }
            </div>
        );
    }
}

Hero.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    contentAlignment: PropTypes.oneOf(['center', 'left', 'right']),
    contentPosition: PropTypes.oneOf(['center', 'top', 'bottom']),
    fullScreen: PropTypes.bool,
    hasShadow: PropTypes.bool,
    heroImage: PropTypes.string,
};

Hero.defaultProps = {
    contentPosition: 'bottom',
    contentAlignment: 'center',
};

export default Hero;
