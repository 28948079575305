import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
// import { unOrphan } from './helpers';
import { gtmPush } from '../../functions/gtm';

const { string } = PropTypes;
export const htmlShape = string;

class HtmlContent extends React.Component {

    static propTypes = {
        className: string,
        html: htmlShape.isRequired,
    };

    // sanitizeContent(content) {
    //     // This just wraps the content in a object that will pass the
    //     // requirements of dangerouslySetInnerHTML
    //     // This gives us an place to sanitize the content: eg. remove script tags etc.
    //     return { __html: unOrphan(content) };
    // }

    handleClick(e) {
        // Listens for clicks on anchor tags, then triggers an event for the application to capture and track
        const { target } = e;
        // Check if the clicked element is an anchor
        if (target.nodeName !== 'A') return;
        // Collect href, current window, link text
        const data = {
            href: target.href,
            location: window.location.href,
            text: target.textContent,
        };
        // trigger event
        // thneed.trigger('contentButtonClicked', 'HtmlContent', data, true);
        gtmPush("audiFoundation", "contentButtonClicked", "HtmlContent", data, 'HtmlContent');
    }

    render() {
        const { className, html } = this.props;
        const classes = classnames(className, 'content-html');

        return (
            <div
                className={classes}
                onClick={this.handleClick}
                dangerouslySetInnerHTML={{__html: html}}
            />
        );
    }
}

export default HtmlContent;
