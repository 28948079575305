import React from 'react';
import Helmet from 'react-helmet';
import { CMSSectionList } from '../components/cms';
import Button from '../audi-ui-components/Button';
import Hero from '../components/Hero';
import Loading from '../components/Loading';
import Thumbnail from '../components/Thumbnail';
import { gtmPageview } from '../functions/gtm';

import {
  PATH_ABOUT,
  PATH_FAQ,
} from '../constants';

import { connect } from 'react-redux';
import { requestPageData } from '../redux/content/pages';
import { requestBoardMembers } from '../redux/content/boardMembers';
const mapStateToProps = state => {
  return {
    hasLoaded: state.pages.about ? state.pages.about.hasLoaded : false,
    pageData: state.pages.about,
    boardMembers: state.boardMembers.data
  }
}
const mapDispatchToProps = dispatch => {
  return {
    requestPageData: (slug) => { dispatch(requestPageData(slug)); },
    requestBoardMembers: () => { dispatch(requestBoardMembers()); }
  }
}

class About extends React.Component {

  componentDidMount() {
    gtmPageview(this.props.location.pathname);
    this.props.requestPageData('about');
    if (!this.props.boardMembers || this.props.boardMembers === null) {
      this.props.requestBoardMembers();
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  render() {
    const { pageData, boardMembers, hasLoaded } = this.props;
    
    return (
      <div className="page about">
        <Helmet>
          <title>About | Audi Foundation</title>
          <meta name="description" content="Audi Foundation About" />
          <meta property="og:site_name" content="Audi Foundation About" />
        </Helmet>

        {pageData && pageData.heroImage && <Hero heroImage={pageData.heroImage} />}
        
        <div className="page-inner">
          
          <div className="content-wrapper">
            {(!pageData || !hasLoaded) && <Loading />}
            {pageData && pageData.sections && <CMSSectionList sections={pageData.sections} />}
            {hasLoaded && <div className="cta-section">
              <p className="mb-3">To read more about our focus and funding principles, read our FAQ.</p>
              <div>
                <p><Button to={PATH_FAQ} variant="primary">Go to FAQ</Button></p>
              </div>
            </div>}
          </div>
        
          <div className="content-wrapper my-4">
            {boardMembers && <>
              <h2 className="aui-headline-4 text-center mb-3"><b>Board members</b></h2>
              <div className="row">
                {boardMembers.map((member, i) => {
                  return (
                    <div key={`board${i}`} className="col-12 col-small-6 col-medium-4">
                      <Thumbnail
                        className="board"
                        thumbnail={member.thumbnail}
                        title={member.name}
                        description={member.shortDescription}
                      />
                    </div>
                  );
                })}
              </div>
            </>}
          </div>
          
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(About);
