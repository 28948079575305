import React from 'react';

export default (_props) => {
  const { className, size, large, small, ...props } = _props;

  // small icon
  if ((size && size.toLowerCase() === 'small') || small) {
    return (
      <svg width="24" height="24" viewBox="0 0 24 24" className={`audiicon audiicon-small ${className ? className : ''}`} {...props}>
        <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
          <path d="M12.9,11.2L16.6,7h-0.9l-3.2,3.7L10,7H7.1l3.8,5.6L7.1,17H8l3.3-3.9L14,17h2.9L12.9,11.2L12.9,11.2z M11.7,12.6l-0.4-0.6 L8.3,7.6h1.3l2.5,3.6l0.4,0.6l3.2,4.6h-1.3L11.7,12.6L11.7,12.6z"/>
        </g>
      </svg>
    );
  }

  // large icon
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" className={`audiicon audiicon-large ${className ? className : ''}`} {...props}>
      <g stroke="currentColor" strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M26.3,22.1l8.9-10.4h-2.1l-7.8,9l-6.2-9H12l9.4,13.6L12,36.3h2.1l8.2-9.5l6.5,9.5H36L26.3,22.1L26.3,22.1z M23.4,25.5 l-0.9-1.4l-7.6-10.8h3.3l6.1,8.7l0.9,1.4l7.9,11.3h-3.3L23.4,25.5L23.4,25.5z"/>
      </g>
    </svg>
  );
};
