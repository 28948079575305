import React from 'react';
import { Link } from 'react-router-dom';
import Social from './Social';
import {
  LINK_FUNDRAISING_LICENSE,
  LINK_LEGAL,
  LINK_PRIVACY,
  PATH_FAQ,
} from '../constants';

const links = [
  { href: LINK_PRIVACY, text: 'Privacy' },
  { href: LINK_LEGAL, text: 'Legal Statement' },
  { to: PATH_FAQ, text: 'FAQs' },
  { href: LINK_FUNDRAISING_LICENSE, text: 'Fundraising License' },
];

const Footer = () => {
  let d = new Date();
  let y = d.getFullYear();
  return (
    <footer className="page-footer text-center text-small-start">
      <div className="content-wrapper">
        <div className="row">
          <div className="col-small-9">
            
            <div className="footer-contact">
              <h5 className="aui-headline-5 mb-1">Contact Us</h5>
              <p className="mb-2">If you have any enquiries, please don’t hesitate to contact the Audi Foundation team via email.
              <br /><a href="mailto:info@audifoundation.com.au" className="aui-textlink">info@audifoundation.com.au</a></p>
            </div>
            
          </div>
        </div>
        
        <div className="row">
          <div className="col-small-3 order-1 order-small-2 footer__social-icons">
            <Social className="text-center text-small-end" />
          </div>
          
          <div className = "col-small-9 order-2 order-small-1">
            <ul className="footer__links mb-2">
              {links.map(link => {
                if (link.to) {
                  return (
                    <li key={link.text}><Link to={link.to} className="aui-textlink">{link.text}</Link></li>
                  );
                }
                return (
                  <li key={link.text}><a href={link.href} target="_blank" rel="nofollow" className="aui-textlink">{link.text}</a></li>
                );
              })}
            </ul>
            <p className="footer__copy">
              &copy; {y} Copyright Audi Foundation. All rights reserved.
            </p>
          </div>
          
        </div>
      </div>
    </footer>
  );
};

export default Footer;
