// import thneed from './thneed';

export const gtmPush = (category, action, label, data = {}, thneedLabel = 'audiFoundation') => {
    let e = {
        event: category,
        eventAction: action,
        eventLabel: label
    };
    if (data) {
        e.data = data;
    }
    if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push(e);
    }
    // thneed.trigger(action, thneedLabel, data, true);
    if (process.env.RAZZLE_APP_ENV !== "production") {
      console.log("gtmPush", e);
    }
};

export const gtmPageview = (path) => {
    if (typeof window !== "undefined" && window.dataLayer) {
        window.dataLayer.push({
            event: 'VirtualPageview',
            virtualPageURL: path,
        });
    }
    // thneed.trigger('pageView', 'audiFoundation', {location: window.location.href}, true);
    if (process.env.RAZZLE_APP_ENV !== "production") {
        console.log("gtmPageview", path);
    }
}
