import React from 'react';
import Helmet from 'react-helmet';
import { CMSSectionList } from '../components/cms';
import Button from '../audi-ui-components/Button';
import Hero from '../components/Hero';
import Loading from '../components/Loading';
import Thumbnail from '../components/Thumbnail';
import AudiMagazineLogo from '../components/AudiMagazineLogo';
import { gtmPageview } from '../functions/gtm';

import {
    PATH_STORIES_SINGLE,
} from '../constants';

import { connect } from 'react-redux';
import { requestArticles } from '../redux/content/articlesList';
import { requestPageData } from '../redux/content/pages';
const mapStateToProps = state => {
  return {
    articlesList: state.articlesList,
    pageData: state.pages.impact,
    hasLoaded: state.pages.impact ? state.pages.impact.hasLoaded : false,
  };
};
const mapDispatchToProps = dispatch => {
  return {
    requestPageData: (slug) => { dispatch(requestPageData(slug)); },
    requestArticles: (opts) => { dispatch(requestArticles(opts)); }
  }
}

class Stories extends React.Component {
  
  componentDidMount() {
    const { match, location, articlesList, requestArticles } = this.props;
    gtmPageview(location.pathname);
    let tag = match.params.tag ? match.params.tag : 'all';
    if (!articlesList[tag]) {
      requestArticles({pageIndex: 0, tag: tag})
    }
    if (!this.props.pageData) {
      this.props.requestPageData('impact');
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }

  getArticles = () => {
    const { articlesList, match } = this.props;
    const tag = match.params.tag ? match.params.tag : 'all';
    const pageIndex = articlesList[tag] ? articlesList[tag].pageIndex : 0;
    this.props.requestArticles({
      pageIndex,
      tag,
    });
  }

  renderArticles() {
    const { articlesList, match, hasLoaded } = this.props;
    const tag = match.params.tag ? match.params.tag : 'all';
    var articles = articlesList && articlesList[tag] ? articlesList[tag].articles : null;
    if (!articles && !hasLoaded) {
      return null;
    }
    if (!articles) {
      return <Loading />;
    }
    if (articles.length) {
      return (
        <div className="row">
        {articles.map((article,i) => {
          if (article.externalUrl) {
            return (
              <div className="col-12 col-small-6 mb-4" key={article.articleId}>
                <Thumbnail
                  category={article.category}
                  className="story-preview external"
                  href={article.externalUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  thumbnail={article.thumbnail}
                  tallHero
                  title={article.title}
                  description={article.shortDescription}
                  button="Read more"
                />
              </div>
            );
          }
          return (
            <div className="col-12 col-small-6 mb-4" key={article.articleId}>
              <Thumbnail
                category={article.category}
                className="story-preview"
                to={`${PATH_STORIES_SINGLE}/${article.slug}`}
                thumbnail={article.thumbnail}
                tallHero
                title={article.title}
                description={article.shortDescription}
                button="Find out more"
              />
            </div>
          );
        })}
      </div>);
    }
    return (
      <p className="text-center py-3">No articles found.</p>
    );
  }

  render() {
    const { articlesList, pageData, hasLoaded, match } = this.props;
    const tag = match.params.tag ? match.params.tag : 'all';
    const canLoadMore = articlesList && articlesList[tag] ? articlesList[tag].canLoadMore : null;
    return (
      <div className="page stories">
        <Helmet>
          <title>Impact | Audi Foundation</title>
        </Helmet>
        
        {pageData && pageData.heroImage && <Hero heroImage={pageData.heroImage} />}
        
        {/*<StoryFilter fixed active={params.tag}/>*/}
        
        <div className="page-inner">
          <div className="content-wrapper">
            {(!pageData || !hasLoaded) && <Loading />}
            
            {pageData && <div className="cta-section mt-0">
              <h1 className="aui-headline-2"><b>{pageData.title}</b></h1>
              <p>{pageData.shortDescription}</p>
            </div>}

            {pageData && pageData.sections && <CMSSectionList sections={pageData.sections} />}
            
            <div className="articles-list">
              {this.renderArticles()}
              {canLoadMore &&
                <div className="py-3 text-center">
                  <Button onClick={this.getArticles} variant="secondary">
                    Load more
                  </Button>
                </div>
              }
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Stories);
