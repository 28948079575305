import React from 'react';
import Helmet from 'react-helmet';
import Loading from '../components/Loading';
import Hero from '../components/Hero';
import Button from '../audi-ui-components/Button';
import { CMSSectionList } from '../components/cms';
import Thumbnail from '../components/Thumbnail';
import { truncateString } from '../functions/stringHelpers';
import { gtmPageview } from '../functions/gtm';

import {
    PATH_DONATE,
    PATH_FAQ,
    PATH_PARTNERS,
    FEATURE_TOGGLE
} from '../constants';

import { connect } from 'react-redux';
import { requestPartnerCategories } from '../redux/content/partnerCategoriesList';
import { requestPageData } from '../redux/content/pages';
const mapStateToProps = state => {
  return {
    hasLoaded: state.pages.home ? state.pages.home.hasLoaded : false,
    pageData: state.pages.home,
    partners: state.partnerCategoriesList.data,
  }
}
const mapDispatchToProps = dispatch => {
  return {
    loadHome: () => { dispatch(requestPageData('home')); },
    requestPartnerCategories: () => { dispatch(requestPartnerCategories()); }
  }
}

class Home extends React.Component {
  
  componentDidMount() {
    gtmPageview("/");
    this.props.loadHome();
    this.props.requestPartnerCategories();
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }
  
  render() {
    const { pageData, partners, hasLoaded } = this.props;

    if (!pageData || !hasLoaded) {
      return <Loading />;
    }

    return (
      <div className="page home">
        <Helmet>
          <title>Home | Audi Foundation</title>
          <meta name="description" content="Audi Foundation Home" />
          <meta property="og:site_name" content="Audi Foundation Home" />
        </Helmet>
        
        {pageData && pageData.heroImage && <Hero heroImage={pageData.heroImage} />}
        
        <div className="page-inner">
          
          <div className="content-wrapper">
            {pageData && pageData.sections && <CMSSectionList sections={pageData.sections} />}
            <div className="cta-section">
              <p className="mb-2">To read more about our focus and funding principles, read our FAQ.</p>
              <div className="d-flex flex-column align-items-center">
                <Button to={PATH_FAQ} variant="secondary" className="mb-2">Go to FAQ</Button>
                {!FEATURE_TOGGLE.DONATE.maintenance && <Button to={PATH_DONATE}
                  onClick={() => {
                    // gtmPush("audiFoundation", "donateNowClick", "TODO", {href: window.location.href});
                  }}
                  variant="primary"
                  className="red"
                >
                  Donate now
                </Button>}
              </div>
            </div>
          </div>
        
          <div className="content-wrapper my-4">
            {partners && <>
              <h2 className="aui-headline-4 mb-2 text-center"><b>Meet Our Charity Partners</b></h2>
              <div className="page-intro mb-3" dangerouslySetInnerHTML={{ __html: this.props.partners.description }} />
              {partners.partnerCategories && partners.partnerCategories.map((category,i) => {
                return (<div key={`c_${i}`} className="partner-category">
                  <h3 className="aui-headline-4 text-center mb-3"><b>{category.title}</b></h3>
                  {category.description && <div className="partner-category--desc" dangerouslySetInnerHTML={{ __html: category.description }} />}
                  <div className="row">
                    {category.partners.length > 0 && category.partners.map(partner => {
                      return (
                        <div className="col-small mb-4" key={partner.slug}>
                          <Thumbnail
                            className="feature-story"
                            to={`${PATH_PARTNERS}/${partner.slug}`}
                            thumbnail={partner.thumbnail}
                            title={partner.title}
                            description={truncateString(partner.description)}
                            className="partner"
                            button="Find out more"
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>);
              })}
            </>}
          </div>
        
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
