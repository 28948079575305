import App from './App';
import { BrowserRouter } from 'react-router-dom';
import React from 'react';
import { hydrate } from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { createAppStore } from './redux';
// import thneed from './functions/thneed'; // eslint-disable-line no-unused-vars

const store = createAppStore(window.__initialState__);

hydrate(
  <ReduxProvider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ReduxProvider>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
